import {
  START_COUNTDOWN,
  UPDATE_COUNTDOWN_TIME,
  SET_COUNTDOWN_EXPIRED,
  RESET_COUNTDOWN
} from './types';

// action creators

const startCountdown = (startTime) => ({
  type: START_COUNTDOWN,
  payload: startTime
});

const updateCountdown = (interval) => ({
  type: UPDATE_COUNTDOWN_TIME,
  payload: interval
});

const resetCountdown = () => ({ type: RESET_COUNTDOWN });

const setExpired = (isExpired) => ({
  type: SET_COUNTDOWN_EXPIRED,
  payload: isExpired
});

// initialize timer var
let countdownTimer;

// thunks

export const startRestCountdown = () => (dispatch, getState) => {
  const startTime = Date.now();

  dispatch(startCountdown(startTime));

  countdownTimer = setInterval(() => {
    const { timeRemaining, startTime } = getState().countdown;

    if (timeRemaining <= 0) {
      dispatch(setExpired(true));
      clearInterval(countdownTimer);
    } else {
      const interval = Date.now() - startTime;
      dispatch(updateCountdown(interval));
    }
  }, 10);
};

export const resetRestCountdown = () => (dispatch) => {
  clearInterval(countdownTimer);
  dispatch(resetCountdown());
};
