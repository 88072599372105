import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Transition } from '@headlessui/react';

import { countdownActions } from 'store/countdown';
import { stopwatchActions } from 'store/stopwatch';
import { trackerActions } from 'store/tracker';
import { useEffect } from 'react';

import { ChevronDoubleRightIcon } from '@heroicons/react/solid';

const { resetRestCountdown } = countdownActions;
const { startStopwatch, resumeStopwatch } = stopwatchActions;
const { startNextObstacle } = trackerActions;

const NextObstacle = () => {
  const dispatch = useDispatch();

  const { currentObstacleNum, onObstacle, isFinished } = useSelector(
    (state) => state.tracker
  );

  const isCountdownExpired = useSelector((state) => state.countdown.isExpired);

  const handleNextObstacle = useCallback(() => {
    // set tracker obstacle
    dispatch(startNextObstacle(currentObstacleNum + 1));
    // reset rest time countdown
    dispatch(resetRestCountdown());
    // start stopwatch if obstacle #1
    if (currentObstacleNum === 0) {
      dispatch(startStopwatch());
    } else {
      // otherwise resume stopwatch
      dispatch(resumeStopwatch());
    }
  }, [currentObstacleNum, dispatch]);

  useEffect(() => {
    if (isCountdownExpired) {
      handleNextObstacle();
    }
  }, [isCountdownExpired, handleNextObstacle]);

  return !isFinished ? (
    <Transition
      show={!onObstacle}
      enter='transition duration-100 delay-75 ease-out'
      enterFrom='transform scale-0 opacity-0'
      enterTo='transform scale-100 opacity-100'
      leave='transition duration-100 ease-in'
      leaveFrom='transform scale-100 opacity-100'
      leaveTo='transform scale-0 opacity-0'
    >
      <div className='mb-2 h-11'>
        <button
          type='button'
          className='flex items-center justify-center w-full h-11 px-4 py-2 border border-transparent shadow-sm rounded-md text-white bg-blue-600'
          onClick={(e) => handleNextObstacle()}
          disabled={onObstacle}
        >
          <ChevronDoubleRightIcon className='h-6 w-6' />
          <div className='ml-1 font-medium uppercase'>Next Obstacle</div>
        </button>
      </div>
    </Transition>
  ) : null;
};

export default NextObstacle;
