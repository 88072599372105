import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import RequireAuth from 'components/RequireAuth';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';
import { validatePassword } from 'utils/password';

import { userActions } from '../../store/user';

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updatePassword } = userActions;

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  });

  const formRef = useRef(null);

  const dispatchUpdatePassword = (data) => {
    try {
      dispatch(updatePassword(data)).then((res) => {
        if (res.actionSuccess) {
          notifySuccess('Password update successful');
          navigate('/');
        } else {
          notifyError('Password update failed');
        }
      });
    } catch (error) {
      notifyError(error.message);
      reset();
    }
  };

  const submitPwdReset = async (formData) => {
    const { currentPassword, newPassword, confirmNewPassword } =
      getTrimmedStringData(formData);

    const isValid = validatePassword(newPassword, confirmNewPassword);
    if (!isValid) return;

    const submitData = {
      currentPassword,
      newPassword
    };

    dispatchUpdatePassword(submitData);
  };

  return (
    <RequireAuth>
      <div className='h-main flex items-start justify-center px-4 sm:px-6 lg:px-8 py-6 md:py-12'>
        <div className='max-w-md w-full space-y-6'>
          <div>
            <h2 className='mt-6 text-center text-3xl font-bold text-white'>
              Update Password
            </h2>
          </div>
          <form
            className='mt-8 space-y-4'
            ref={formRef}
            onSubmit={handleSubmit(submitPwdReset)}
          >
            <div>
              <label
                htmlFor='currentPassword'
                className='block text-sm font-medium text-gray-300'
              >
                Current Password
              </label>
              <input
                id='currentPassword'
                {...register('currentPassword')}
                name='currentPassword'
                type='password'
                autoComplete='current-password'
                required
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
                placeholder='Current Password'
              />
            </div>
            <div>
              <label
                htmlFor='newPassword'
                className='block text-sm font-medium text-gray-300'
              >
                New Password
              </label>
              <input
                id='newPassword'
                {...register('newPassword')}
                name='newPassword'
                type='password'
                autoComplete='new-password'
                required
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
                placeholder='New Password'
              />
            </div>
            <div>
              <label
                htmlFor='confirmNewPassword'
                className='block text-sm font-medium text-gray-300'
              >
                Confirm New Password
              </label>
              <input
                id='confirmNewPassword'
                {...register('confirmNewPassword')}
                name='confirmNewPassword'
                type='password'
                autoComplete='new-password'
                required
                className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
                placeholder='Confirm New Password'
              />
            </div>

            <div className='pt-2'>
              <button
                type='submit'
                className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                <span className='flex items-center pl-3 uppercase'>
                  Update Password
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </RequireAuth>
  );
};

export default PasswordUpdate;
