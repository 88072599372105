import { Outlet, useOutletContext } from 'react-router-dom';

import OfficialsOnly from 'components/OfficialsOnly';

const CompAthletes = () => {
  const { competition, competitionId } = useOutletContext();

  return (
    <OfficialsOnly competition={competition}>
      <Outlet context={{ competition, competitionId }} />
    </OfficialsOnly>
  );
};

export default CompAthletes;
