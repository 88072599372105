import { Tab } from '@headlessui/react';

const ObstaclesPanel = ({ obstacles }) => {
  const isEmpty = obstacles.length < 1;

  return (
    <Tab.Panel>
      <ul className='mt-3 grid grid-cols-1 gap-3 sm:gap-6 md:grid-cols-2 rounded'>
        {!isEmpty ? (
          obstacles.map(({ number, name, bonusPoints }) => (
            <li
              key={number}
              className='col-span-1 h-16 flex shadow-sm rounded-md'
            >
              <div
                className={
                  'flex-shrink-0 flex items-center justify-center w-16 border-t border-r border-b border-gray-200 bg-white font-medium rounded-l-md'
                }
              >
                <span className='flex items-center justify-center h-8 w-8 rounded-full text-white bg-gray-700'>
                  {number}
                </span>
              </div>
              <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md'>
                <div className='flex-1 px-4 py-4 text-sm'>
                  <div className='text-gray-800 font-medium uppercase'>
                    {name}
                  </div>
                </div>
                <div className='px-2 py-1 text-white text-sm rounded-md bg-indigo-500'>
                  {bonusPoints} Bonus Points
                </div>
                <div className='flex-shrink-0 pr-2'></div>
              </div>
            </li>
          ))
        ) : (
          <li className='md:col-start-1 md:col-end-3 h-16 px-4 py-5 bg-white shadow-sm rounded-md'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
              No Obstacles Added
            </h3>
          </li>
        )}
      </ul>
    </Tab.Panel>
  );
};

export default ObstaclesPanel;
