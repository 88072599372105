import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'components/Footer';

const Users = () => {
  return (
    <main className='h-main overflow-y-auto flex flex-col max-w-4xl mx-auto px-2 sm:px-24 py-4 sm:py-8'>
      <Outlet />

      <div className='my-2'>
        <Footer />
      </div>
    </main>
  );
};

export default Users;
