import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import Loader from 'components/Loader';
import LeaderboardItems from './LeaderboardItems';

const Leaderboard = () => {
  const { id } = useParams();
  console.log('param:', id);
  const fetchURL =
    id === 'testing'
      ? '/api/leaderboards/name/testing'
      : id
      ? `/api/leaderboards/seasons/${id}`
      : '/api/leaderboards/current';

  const getLeaderboard = async (fetchURL) => {
    const { data } = await axios.get(fetchURL);

    return data;
  };

  const useLeaderboard = () =>
    useQuery(['leaderboard', fetchURL], () => getLeaderboard(fetchURL));

  const { data, error, isFetching } = useLeaderboard();

  const leaders = data && data?.leaderboard ? data.leaderboard : [];

  return (
    <>
      {isFetching && !data ? (
        <Loader />
      ) : leaders.length > 0 ? (
        <LeaderboardItems leaders={leaders} />
      ) : (
        <div className='mx-8 p-3 bg-primary  text-center text-gray-300 rounded-md'>
          No results for this season
        </div>
      )}
      {error && <div>Error: {error.message}</div>}
    </>
  );
};

export default Leaderboard;
