import { useDispatch, useSelector } from 'react-redux';

import { trackerOperations } from 'store/tracker';

import { classNames } from 'utils/classNames';

const { updateBonusCompleted, updateBonusCompletedAndTotalPoints } =
  trackerOperations;

const ObstacleBonus = ({
  obstacleNumber,
  bonusPoints,
  bonusCompleted,
  disabled
}) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state) => state.tracker.isFinished);

  const toggleBonusCompleted = () => {
    if (isFinished) {
      dispatch(
        updateBonusCompletedAndTotalPoints(
          obstacleNumber,
          !bonusCompleted,
          bonusPoints
        )
      );
    } else {
      dispatch(updateBonusCompleted(obstacleNumber, !bonusCompleted));
    }
  };

  return (
    <button
      type='button'
      onClick={() => toggleBonusCompleted()}
      disabled={disabled}
      className={classNames(
        'mr-2 inline-flex items-center px-2 py-1 border text-xs font-medium rounded',
        bonusCompleted && !disabled
          ? 'border-transparent text-white bg-indigo-600 hover:bg-indigo-700'
          : 'border-gray-200 text-gray-800 bg-white '
      )}
    >
      <span className='font-bold uppercase'>
        <span className='text-sm'>+</span>
        {bonusPoints} Bonus
      </span>
    </button>
  );
};

export default ObstacleBonus;
