import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import AdminsOnly from 'components/AdminsOnly';
import Loader from 'components/Loader';
import Four04 from 'components/Four04';
import UserEditForm from './UserEditForm';

const UserEdit = () => {
  const { id } = useParams();
  const [fetchError, setFetchError] = useState(null);

  const getUser = async () => {
    try {
      const {
        data: { user }
      } = await axios.get(`/api/users/${id}`);

      return user;
    } catch (error) {
      console.log('error: ', error);
      setFetchError(error);
    }
  };
  const useUser = () => useQuery('userEdit', getUser);

  const { data, error, isFetching } = useUser();

  return (
    <AdminsOnly>
      <div className='grow'>
        {isFetching && <Loader />}
        {data && <UserEditForm user={data} userId={id} />}
        {fetchError && <Four04 />}
        {error && <div>Error: {error}</div>}
      </div>
    </AdminsOnly>
  );
};

export default UserEdit;
