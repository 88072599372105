import { useState } from 'react';
import { Link } from 'react-router-dom';

import LeaderboardFilters from './LeaderboardFilters';

import { ChevronRightIcon } from '@heroicons/react/solid';

import {
  ageGroupsFilters,
  divisionsFilters,
  filterLeaderboard
} from 'utils/leaderboard';

const LeaderboardItems = ({ leaders }) => {
  const [ageGroupFilters, setAgeGroupFilters] = useState(ageGroupsFilters);
  const [divisionFilters, setDivisionFilters] = useState(divisionsFilters);

  const handleAgeGroupChange = (group, checked) => {
    const updatedAgeGroupFilters = [...ageGroupFilters];
    const targetIdx = ageGroupFilters.findIndex(
      (ageGroup) => ageGroup.value === group
    );
    updatedAgeGroupFilters[targetIdx].checked = checked;

    setAgeGroupFilters(updatedAgeGroupFilters);
  };

  const handleDivisionFilterChange = (division, checked) => {
    const updatedDivisionFilters = [...divisionFilters];
    const targetIdx = divisionFilters.findIndex(
      (div) => div.value === division
    );
    updatedDivisionFilters[targetIdx].checked = checked;

    setDivisionFilters(updatedDivisionFilters);
  };

  const handleClearAllFilters = () => {
    const updatedAgeGroupFilters = ageGroupsFilters.map((ageGroup) => ({
      value: ageGroup.value,
      checked: false
    }));

    const updatedDivisionFilters = divisionsFilters.map((division) => ({
      value: division.value,
      checked: false
    }));

    setAgeGroupFilters(updatedAgeGroupFilters);
    setDivisionFilters(updatedDivisionFilters);
  };

  const filteredLeaders = filterLeaderboard(leaders, {
    ageGroup: ageGroupFilters,
    division: divisionFilters
  });

  return (
    <>
      <div className='mb-2'>
        <LeaderboardFilters
          ageGroupFilters={ageGroupFilters}
          divisionFilters={divisionFilters}
          handleAgeGroupChange={handleAgeGroupChange}
          handleDivisionFilterChange={handleDivisionFilterChange}
          handleClearAllFilters={handleClearAllFilters}
        />
      </div>

      <div className='bg-white shadow rounded-md'>
        <ul className='flex flex-col divide-y divide-gray-200'>
          {filteredLeaders.map((athlete, idx) => (
            <li key={athlete.id}>
              <Link
                to={`/athletes/${athlete.id}`}
                className='block hover:bg-gray-50'
              >
                <div className='p-3 flex items-center sm:px-6'>
                  <div className='min-w-0 flex-1 flex items-center justify-between gap-x-8'>
                    <div className='min-w-[11rem] flex flex-col items-start md:flex-row md:gap-x-8'>
                      <div className='flex items-center sm:w-48 font-medium text-base text-indigo-600'>
                        <span className='flex items-center justify-center h-6 w-6 rounded-full font-bold text-white bg-indigo-600'>
                          {idx + 1}
                        </span>
                        <span className='ml-2 lg:ml-4'>{`${athlete.firstName} ${athlete.lastName}`}</span>
                      </div>
                      <div className='text-left font-normal text-sm text-gray-500'>
                        <div>{athlete.country}</div>
                        <div className='text-sm text-gray-500 uppercase'>
                          {athlete.division}
                        </div>
                      </div>
                    </div>
                    <div className='text-left flex flex-col'>
                      <div className='font-medium text-sm sm:text-base text-indigo-600'>
                        {athlete.seasonScore} Points
                      </div>
                      <div className='text-sm'>
                        {athlete.seasonEventCount} Events
                      </div>
                      <div className='sm:w-40 text-sm text-gray-500'>
                        {athlete.ageGroup}
                      </div>
                    </div>
                  </div>
                  <div className='ml-2'>
                    <ChevronRightIcon
                      className='h-7 w-7 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LeaderboardItems;
