import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AccountSettings from 'components/AccountSettings';
import App from 'App';
import Athlete from 'components/Athlete';
import AthleteDisplay from 'components/Athlete/AthleteDisplay';
import AthleteEdit from 'components/AthleteEdit';
import AthleteForm from 'components/AthleteForm';
import AthletesList from 'components/AthletesList';
import Athletes from 'components/Athletes';
import {
  CompAthletes,
  CompAthleteForm,
  CompAthletesList
} from 'components/CompAthletes';
import Competition from 'components/Competition';
import CompetitionDisplay from 'components/Competition/CompetitionDisplay';
import CompetitionEdit from 'components/CompetitionEdit';
import CompetitionForm from 'components/CompetitionForm';
import CompetitionTracker from 'components/CompetitionTracker';
import Competitions from 'components/Competitions';
import CompetitionsList from 'components/CompetitionsList';
import { CompScores, CompScoresEdit } from 'components/CompScores';
import {
  CompOfficials,
  CompOfficialsList,
  CompOfficialAdd
} from 'components/CompOfficials';
import {
  CompObstacles,
  CompObstaclesList,
  ObstacleEdit,
  ObstacleForm
} from 'components/CompObstacles';
import Four04 from 'components/Four04';
import Leaderboard from 'components/Leaderboard';
import Leaderboards from 'components/Leaderboards';
import PasswordReset from 'components/PasswordReset';
import PasswordResetRequest from 'components/PasswordResetRequest';
import PasswordUpdate from 'components/PasswordUpdate';
import Register from 'components/Register';
import SignIn from 'components/Signin';
import SignOut from 'components/SignOut';
import Splash from 'components/Splash';
import Support from 'components/Support';
import Users from 'components/Users';
import UserEdit from 'components/Users/UserEdit';
import UserForm from 'components/Users/UserForm';
import UsersList from 'components/Users/UsersList';

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<App />}>
      <Route index element={<Splash />} />

      <Route path='leaderboards' element={<Leaderboards />}>
        <Route path=':id' element={<Leaderboard />} />
        <Route index element={<Leaderboard />} />
      </Route>

      <Route path='competitions/:id/tracker' element={<CompetitionTracker />} />
      <Route path='competitions' element={<Competitions />}>
        <Route path=':id' element={<Competition />}>
          <Route path='edit' element={<CompetitionEdit />} />

          <Route path='obstacles' element={<CompObstacles />}>
            <Route path='edit' element={<ObstacleEdit />} />
            <Route path='add' element={<ObstacleForm />} />
            <Route index element={<CompObstaclesList />} />
          </Route>

          <Route path='athletes' element={<CompAthletes />}>
            <Route path='add' element={<CompAthleteForm />} />
            <Route index element={<CompAthletesList />} />
          </Route>

          <Route path='officials' element={<CompOfficials />}>
            <Route path='add' element={<CompOfficialAdd />} />
            <Route index element={<CompOfficialsList />} />
          </Route>

          <Route path='scores' element={<CompScores />} />
          <Route path='scores/edit' element={<CompScoresEdit />} />
          <Route index element={<CompetitionDisplay />} />
        </Route>

        <Route path='new' element={<CompetitionForm />} />
        <Route index element={<CompetitionsList />} />
      </Route>

      <Route path='athletes' element={<Athletes />}>
        <Route path=':id' element={<Athlete />}>
          <Route path='edit' element={<AthleteEdit />} />
          <Route index element={<AthleteDisplay />} />
        </Route>

        <Route path='new' element={<AthleteForm />} />
        <Route index element={<AthletesList />} />
      </Route>

      <Route path='users' element={<Users />}>
        <Route path=':id/edit' element={<UserEdit />} />
        <Route path='new' element={<UserForm />} />
        <Route index element={<UsersList />} />
      </Route>

      <Route path='/account' element={<AccountSettings />} />
      <Route path='/support' element={<Support />} />
      <Route path='/register/:token' element={<Register />} />
      <Route path='/signin' element={<SignIn />} />
      <Route path='/signout' element={<SignOut />} />
      <Route path='/password-update' element={<PasswordUpdate />} />
      <Route path='/password-reset/:token' element={<PasswordReset />} />
      <Route
        path='/password-reset/request'
        element={<PasswordResetRequest />}
      />
      <Route path='*' element={<Four04 />} />
    </Route>
  </Routes>
);

export default AppRoutes;
