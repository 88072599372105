import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tab } from '@headlessui/react';

import CompetitionHeader from 'components/CompetitionHeader';
import CompetitionMenu from './CompetitionMenu';
import ObstaclesPanel from './ObstaclesPanel';
import AthletesPanel from './AthletesPanel';
import OfficialsPanel from './OfficialsPanel';

import { competitionActions } from 'store/competition';
import { StarIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/classNames';
import { MINIMUM_NUMBER_OBSTACLES } from 'utils/constants';
import { checkIsUserCompetitionAuthorized } from 'utils/officials';

const { updateCompetitionStatus } = competitionActions;

const CompetitionDisplay = () => {
  const navigate = useNavigate();
  const { competition, competitionId } = useOutletContext();
  const { loggedIn, user } = useSelector((state) => state.user);
  const { status, obstacles, athletes, officials } = competition;

  const isOfficial =
    loggedIn && checkIsUserCompetitionAuthorized(user, competition);

  const isReady =
    obstacles.length >= MINIMUM_NUMBER_OBSTACLES && athletes.length > 1;

  const showScoresBtn = status !== 'upcoming' && !isOfficial;

  const handleStartCompetition = async () => {
    await updateCompetitionStatus(competitionId, 'in progress');

    navigate(`/competitions/${competitionId}/tracker`, {
      state: {
        competition: {
          ...competition,
          status: 'in progress'
        }
      }
    });
  };

  return (
    <div className='relative flex flex-col'>
      <CompetitionHeader
        backPath={`/competitions`}
        competition={competition}
        competitionId={competitionId}
      />

      {isOfficial && (
        <CompetitionMenu
          competitionId={competitionId}
          competition={competition}
          isReady={isReady}
          userIsAdmin={user.role === 'admin'}
          handleStartCompetition={handleStartCompetition}
        />
      )}

      <div className='w-full lg:max-w-3xl lg:mx-auto px-2 py-4 sm:px-0'>
        {showScoresBtn && (
          <Link
            to={`/competitions/${competitionId}/scores`}
            className='md:w-40'
          >
            <button
              title='View Scores'
              className='mb-3 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm font-medium text-sm text-white uppercase bg-indigo-600 hover:bg-indigo-800'
            >
              <StarIcon className='h-5 w-5 mr-1' aria-hidden='true' />
              View Scores
            </button>
          </Link>
        )}

        <Tab.Group>
          <Tab.List className='flex p-1 space-x-1 bg-blue-900/20 rounded-xl'>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium uppercase text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              Obstacles: {obstacles.length}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium uppercase text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              Athletes: {athletes.length}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium uppercase text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              Officials: {officials.length}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <ObstaclesPanel obstacles={obstacles} />
            <AthletesPanel athletes={athletes} />
            <OfficialsPanel officials={officials} isOfficial={isOfficial} />
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default CompetitionDisplay;
