import { useRef } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { userActions } from '../../store/user';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';

const SignIn = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const isAuthProcessing = useSelector((state) => state.user.isAuthProcessing);

  let from = location.state?.from?.pathname || '/';

  const { login } = userActions;

  const { handleSubmit, register } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const formRef = useRef(null);

  const signIn = async (data) => {
    try {
      const { actionSuccess } = await dispatch(login(data));

      if (actionSuccess) {
        notifySuccess('You are signed in');
        navigate(from);
      } else {
        notifyError('Sign in attempt failed');
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  const submitSignIn = async (formData) => {
    if (isAuthProcessing) return;

    const submitData = getTrimmedStringData(formData);

    if (submitData.password === '') {
      notifyError('Password cannot be empty');
      return;
    } else if (submitData.email === '') {
      notifyError('Email cannot be empty');
      return;
    }

    signIn({ data: submitData });
  };

  return isLoggedIn ? (
    <Navigate to={from ? from : '/'} replace={true} />
  ) : (
    <div className='h-main flex items-start justify-center px-4 sm:px-6 lg:px-8 py-6 md:py-12'>
      <div className='max-w-md w-full space-y-6'>
        <div>
          <h2 className='mt-6 text-center text-3xl font-bold text-white'>
            Sign In
          </h2>
        </div>
        <form
          className='mt-8 space-y-4'
          ref={formRef}
          onSubmit={handleSubmit(submitSignIn)}
        >
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-300'
            >
              Email address
            </label>
            <input
              id='email'
              {...register('email')}
              name='email'
              type='email'
              autoComplete='email'
              required
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
              placeholder='Email address'
            />
          </div>
          <div>
            <label
              htmlFor='password'
              className='block text-sm font-medium text-gray-300'
            >
              Password
            </label>
            <input
              id='password'
              {...register('password')}
              name='password'
              type='password'
              autoComplete='current-password'
              required
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
              placeholder='Password'
            />
          </div>

          <div className='pt-2'>
            <button
              type='submit'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              disabled={isLoggedIn}
            >
              <span className='flex items-center pl-3 uppercase'></span>
              Sign in
            </button>
          </div>
        </form>

        <div className='flex items-center justify-center space-y-reverse'>
          <div className='text-sm'>
            <Link
              to='/password-reset/request'
              className='font-medium text-white hover:text-indigo-500'
            >
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
