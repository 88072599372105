import {
  AUTH_PROCESSING,
  AUTO_LOGIN_USER_FAILURE,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER_FAILURE,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SET_USER_DATA
} from './types';

import {
  getAuthTokenFromLS,
  saveAuthTokenToLS,
  removeAuthTokenFromLS
} from '../../utils/auth-token';

export const userInitialState = {
  loggedIn: false,
  user: {},
  authToken: getAuthTokenFromLS(),
  isAuthProcessing: false
};

const reducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_USER_SUCCESS:
    case REGISTER_USER_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      const { token } = payload;
      saveAuthTokenToLS(token);

      return {
        ...state,
        loggedIn: true,
        token: token
      };

    case LOGIN_USER_FAILURE:
    case LOGOUT_USER:
    case REGISTER_USER_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case AUTO_LOGIN_USER_FAILURE:
      removeAuthTokenFromLS();

      return {
        loggedIn: false,
        user: {},
        token: null
      };

    case SET_USER_DATA:
      return {
        ...state,
        loggedIn: true,
        user: { ...payload }
      };

    case AUTH_PROCESSING:
      return {
        ...state,
        isAuthProcessing: payload
      };

    default:
      return state;
  }
};

export default reducer;
