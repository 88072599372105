import { useDispatch, useSelector } from 'react-redux';

import { competitionActions } from 'store/competition';
import { countdownActions } from 'store/countdown';
import { trackerOperations } from 'store/tracker';

import { SaveIcon, ClipboardCheckIcon } from '@heroicons/react/solid';

const { updateAthleteResult } = competitionActions;
const { resetRestCountdown } = countdownActions;
const { markAthleteDone } = trackerOperations;

const Status = ({ label, number }) => (
  <div className='flex items-center text-lg leading-6 font-medium text-white uppercase'>
    {label}
    {number && (
      <span
        className={
          'flex-shrink-0 flex items-center justify-center w-14 font-medium'
        }
      >
        <span className='flex items-center justify-center h-8 w-8 rounded-full text-white bg-blue-700'>
          {number}
        </span>
      </span>
    )}
  </div>
);

const StatusArea = ({ resetAthleteSelector }) => {
  const dispatch = useDispatch();

  const { currentObstacleNum, onObstacle, isFinished } = useSelector(
    (state) => state.tracker
  );

  const handleDone = () => {
    dispatch(markAthleteDone());
    dispatch(resetRestCountdown());
  };

  const handleSave = async () => {
    dispatch(updateAthleteResult());
    resetAthleteSelector();
  };

  return (
    <div className='mb-2 px-1'>
      <div className='flex justify-between'>
        {isFinished ? (
          <Status label='Course Completed' />
        ) : onObstacle ? (
          <Status label='On Obstacle' number={currentObstacleNum} />
        ) : (
          <Status label='Next Obstacle' number={currentObstacleNum + 1} />
        )}

        <div className='sm:flex-shrink-0 sm:flex sm:items-center'>
          {isFinished ? (
            <button
              type='button'
              title='Done'
              className='flex items-center px-3 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-700'
              onClick={() => {
                handleSave();
              }}
            >
              <SaveIcon className='w-6 h-6 -translate-y-[2px]' />
              <span className='ml-1 uppercase'>Save Results</span>
            </button>
          ) : currentObstacleNum > 0 && !onObstacle ? (
            <button
              type='button'
              title='Done'
              className='flex items-center px-3 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-700'
              onClick={() => {
                handleDone();
              }}
            >
              <ClipboardCheckIcon className='w-6 h-6' />
              <span className='ml-1 uppercase'>Done</span>
            </button>
          ) : (
            <div className='h-10'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusArea;
