import { Outlet } from 'react-router-dom';

import LeaderboardSelect from './LeaderboardSelect';
import Footer from 'components/Footer';

const Leaderboards = () => {
  return (
    <div className='h-main flex flex-col justify-between items-center'>
      <main className='w-full h-full overflow-y-auto flex flex-col max-w-4xl mx-auto px-2 sm:px-24 pt-2'>
        <div className='flex items-center justify-center mb-2'>
          <h1 className='text-center text-2xl md:text-3xl font-normal text-red'>
            Leaderboard:
          </h1>
          <LeaderboardSelect />
        </div>

        <Outlet />

        <div className='mt-auto mb-2'>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Leaderboards;
