import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import RequireAuth from 'components/RequireAuth';
import CountryOptions from '../CountryOptions';
import FormErrorMessage from 'components/FormErrorMessage';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';
import { EMAILPATTERN } from 'utils/constants';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const AthleteEditForm = ({ athlete, athleteId }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    firstName,
    lastName,
    email,
    dob,
    gender,
    division,
    city,
    state,
    country
  } = athlete;

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      dob,
      gender,
      division,
      city,
      state,
      country
    }
  });

  const formRef = useRef(null);

  const updateAthlete = async (athleteData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { athleteData } });

    return await axios
      .put(`/api/athletes/${athleteId}`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitAthlete = async (formData) => {
    try {
      const submitData = getTrimmedStringData(formData);

      const { data } = await updateAthlete(submitData);

      if (data.success) {
        notifySuccess('Athlete updated');
        queryClient.invalidateQueries('athletes');

        navigate(`/athletes/${athleteId}`);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <RequireAuth>
      <div className='relative lg:max-w-2xl lg:mx-auto rounded h-full pb-10'>
        <h1 className='text-center text-2xl md:text-3xl md:mb-8 text-red'>
          Edit Athlete
        </h1>

        <form ref={formRef} onSubmit={handleSubmit(submitAthlete)}>
          <div className='shadow sm:rounded-md'>
            <div className='grid grid-cols-6 gap-3 md:gap-4'>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='firstName'
                  className='block text-sm font-medium text-gray-200'
                >
                  First name
                </label>
                <input
                  type='text'
                  name='firstName'
                  id='firstName'
                  {...register('firstName', {
                    required: 'Athlete first name is required',
                    minLength: {
                      value: 2,
                      message: 'Minimum length is 2'
                    }
                  })}
                  autoComplete='given-name'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
                {errors.firstName && (
                  <FormErrorMessage message={errors.firstName.message} />
                )}
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='lastName'
                  className='block text-sm font-medium text-gray-200'
                >
                  Last name
                </label>
                <input
                  type='text'
                  name='lastName'
                  id='lastName'
                  {...register('lastName', {
                    required: 'Athlete last name is required',
                    minLength: {
                      value: 2,
                      message: 'Minimum length is 2'
                    }
                  })}
                  autoComplete='family-name'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
                {errors.lastName && (
                  <FormErrorMessage message={errors.lastName.message} />
                )}
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-200'
                >
                  Email address
                </label>
                <input
                  type='text'
                  name='email'
                  id='email'
                  {...register('email', {
                    required: 'Email address is required',
                    pattern: {
                      value: EMAILPATTERN,
                      message: 'Invalid email address'
                    },
                    maxLength: 50
                  })}
                  autoComplete='email'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
                {errors.email && (
                  <FormErrorMessage message={errors.email.message} />
                )}
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='gender'
                  className='block text-sm font-medium text-gray-200'
                >
                  Division
                </label>
                <select
                  id='division'
                  {...register('division', {
                    validate: (value) =>
                      value !== 'select' || 'Division is required'
                  })}
                  name='division'
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                >
                  <option value='select'>Select Division</option>
                  <option value='intro'>Intro</option>
                  <option value='intermediate'>Intermediate</option>
                  <option value='advanced'>Advanced</option>
                  <option value='elite'>Elite</option>
                </select>
                {errors.division && (
                  <FormErrorMessage message={errors.division.message} />
                )}
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='gender'
                  className='block text-sm font-medium text-gray-200'
                >
                  Gender
                </label>
                <select
                  id='gender'
                  {...register('gender', {
                    validate: (value) =>
                      value !== 'select' || 'Athlete gender is required'
                  })}
                  name='gender'
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                >
                  <option value='select'>Select Gender</option>
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                </select>
                {errors.gender && (
                  <FormErrorMessage message={errors.gender.message} />
                )}
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='dob'
                  className='block text-sm font-medium text-gray-200'
                >
                  Date of Birth
                </label>
                <input
                  type='date'
                  name='dob'
                  id='dob'
                  {...register('dob', {
                    required: 'Athlete date of birth is required',
                    valueAsDate: true
                  })}
                  max='2009-01-01'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
                {errors.dob && (
                  <FormErrorMessage message={errors.dob.message} />
                )}
              </div>

              <div className='col-span-6'>
                <label
                  htmlFor='city'
                  className='block text-sm font-medium text-gray-200'
                >
                  City
                </label>
                <input
                  type='text'
                  name='city'
                  id='city'
                  {...register('city', {
                    required: 'Athlete city is required',
                    minLength: {
                      value: 2,
                      message: 'Minimum length is 2'
                    }
                  })}
                  autoComplete='address-level2'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
                {errors.city && (
                  <FormErrorMessage message={errors.city.message} />
                )}
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='region'
                  className='block text-sm font-medium text-gray-200'
                >
                  State / Province
                </label>
                <input
                  type='text'
                  name='state'
                  id='state'
                  {...register('state', {
                    required: 'Athlete state is required',
                    minLength: {
                      value: 2,
                      message: 'Minimum length is 2'
                    }
                  })}
                  autoComplete='address-level1'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
                {errors.state && (
                  <FormErrorMessage message={errors.state.message} />
                )}
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='country'
                  className='block text-sm font-medium text-gray-200'
                >
                  Country
                </label>
                <select
                  name='country'
                  id='country'
                  {...register('country', {
                    validate: (value) =>
                      value !== 'select' || 'Athlete country is required'
                  })}
                  autoComplete='country-name'
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                  defaultValue={'Select a country'}
                >
                  <CountryOptions />
                </select>
                {errors.country && (
                  <FormErrorMessage message={errors.country.message} />
                )}
              </div>
            </div>
          </div>
          <button
            type='submit'
            className='w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <CheckIcon className='h-5 w-5' aria-hidden='true' />
            <div className='uppercase'>Update Athlete</div>
          </button>
        </form>
        <Link
          to={`/athletes/${athleteId}`}
          className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
        >
          <button
            type='button'
            title='Cancel'
            className='flex justify-center gap-1 w-full mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <XIcon className='h-5 w-5' aria-hidden='true' />
            <span className='font-medium uppercase'>Cancel</span>
          </button>
        </Link>
      </div>
    </RequireAuth>
  );
};

export default AthleteEditForm;
