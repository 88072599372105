import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import FormErrorMessage from 'components/FormErrorMessage';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';
import { EMAILPATTERN } from 'utils/constants';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const UserEditForm = ({ user }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role
    }
  });

  console.log('errors: ', errors);

  const formRef = useRef(null);

  const updateUser = async (officialData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { officialData } });

    return await axios
      .put(`/api/users/${user._id}`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitOfficial = async (formData) => {
    try {
      const submitData = getTrimmedStringData(formData);

      const { firstName, lastName, email, role } = submitData;

      const officialData = {
        userData: {
          firstName,
          lastName,
          email
        },
        userRole: role
      };

      const { data } = await updateUser(officialData);

      if (data.success) {
        notifySuccess('User updated');
        queryClient.invalidateQueries('users');

        navigate(`/users`);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='relative lg:max-w-2xl lg:mx-auto rounded h-full pb-10'>
      <h2 className='text-center text-2xl md:text-3xl md:mb-8 text-red uppercase'>
        Edit User
      </h2>
      <form
        className='min-h-fit'
        ref={formRef}
        onSubmit={handleSubmit(submitOfficial)}
      >
        <div className='shadow sm:rounded-md'>
          <div className='grid grid-cols-6 gap-3 md:gap-4'>
            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium text-gray-200'
              >
                First name
              </label>
              <input
                type='text'
                name='firstName'
                id='firstName'
                {...register('firstName', {
                  required: 'First name is required',
                  minLength: {
                    value: 2,
                    message: 'Minimum length is 2'
                  }
                })}
                autoComplete='given-name'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
              {errors.firstName && (
                <FormErrorMessage message={errors.firstName.message} />
              )}
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='lastName'
                className='block text-sm font-medium text-gray-200'
              >
                Last name
              </label>
              <input
                type='text'
                name='lastName'
                id='lastName'
                {...register('lastName', {
                  required: 'Last name is required',
                  minLength: {
                    value: 2,
                    message: 'Minimum length is 2'
                  }
                })}
                autoComplete='family-name'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
              {errors.lastName && (
                <FormErrorMessage message={errors.lastName.message} />
              )}
            </div>
            <div className='col-span-6'>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-200'
              >
                Email address
              </label>
              <input
                type='text'
                name='email'
                id='email'
                {...register('email', {
                  required: 'Email address is required',
                  pattern: {
                    value: EMAILPATTERN,
                    message: 'Invalid email address'
                  },
                  maxLength: 50
                })}
                autoComplete='email'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
              {errors.email && (
                <FormErrorMessage message={errors.email.message} />
              )}
            </div>
            <div className='col-span-6'>
              <label
                htmlFor='role'
                className='block text-sm font-medium text-gray-200'
              >
                Role
              </label>
              <select
                id='role'
                {...register('role', {
                  validate: (value) =>
                    value !== 'select' || 'User role is required'
                })}
                name='role'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
              >
                <option value='select'>Select Role</option>
                <option value='admin'>Admin</option>
                <option value='organizer'>Organizer</option>
              </select>
              {errors.role && (
                <FormErrorMessage message={errors.role.message} />
              )}
            </div>
          </div>
        </div>
        <button
          type='submit'
          className='w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
          <div className='uppercase'>Update User</div>
        </button>
      </form>
      <Link
        to={`/users`}
        className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Cancel'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <XIcon className='h-5 w-5' aria-hidden='true' />
          <span className='uppercase'>Cancel</span>
        </button>
      </Link>
    </div>
  );
};

export default UserEditForm;
