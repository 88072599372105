import { Link } from 'react-router-dom';

import { ArrowLeftIcon, CalendarIcon } from '@heroicons/react/solid';

import { formatDateMDYT } from 'utils/date';

import { PencilAltIcon } from '@heroicons/react/solid';
import { classNames } from 'utils/classNames';

const CompetitionHeader = ({
  backPath,
  competition,
  competitionId,
  showEditBtn
}) => {
  const { name, date, city, state, country, hostGym, status } = competition;
  const backBtnPath = backPath || `/competitions/${competitionId}`;

  return (
    <>
      <button
        type='button'
        className='absolute top-0 left-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      >
        <Link to={backBtnPath} className='inline-flex items-center'>
          <ArrowLeftIcon className='h-5 w-5' aria-hidden='true' />
        </Link>
      </button>

      <div className='text-center'>
        <div className='flex justify-center items-baseline'>
          <h1
            className={classNames(
              'text-2xl md:text-3xl text-red',
              showEditBtn && 'ml-8'
            )}
          >
            {name}
          </h1>
          {showEditBtn && (
            <Link
              to={`/competitions/${competitionId}/edit`}
              className='translate-x-4 inline-flex items-center'
            >
              <button
                title='Edit Competition'
                className='inline-flex items-center p-1 rounded-full shadow-sm text-blue-600 sm:text-gray-300 bg-transparent transition-all hover:text-blue-600 hover:scale-125'
              >
                <PencilAltIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </Link>
          )}
        </div>
        {hostGym && (
          <h2 className='text-lg text-gray-300 uppercase'>{hostGym}</h2>
        )}
        <p className='sm:ml-3 flex-shrink-0 text-lg font-normal text-gray-300'>
          {city}, {state ? `${state}, ` : ''} {country}
        </p>
        <div className='flex justify-center items-center text-base text-gray-300'>
          <CalendarIcon
            className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300'
            aria-hidden='true'
          />
          <div>
            <time className='font-bold' dateTime={date}>
              {formatDateMDYT(date)}
            </time>
          </div>
        </div>
        <div
          className={`${
            status === 'upcoming'
              ? 'text-gray-400'
              : status === 'in progress'
              ? 'text-green-600'
              : 'text-blue-600'
          } text-center text-sm font-bold uppercase -space-x-1`}
        >
          {status}
        </div>
      </div>
    </>
  );
};

export default CompetitionHeader;
