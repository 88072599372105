import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import ConfirmDelete from 'components/ConfirmDelete';

import { notifyError, notifySuccess } from 'utils/toast';

import { PencilAltIcon, PlusIcon } from '@heroicons/react/solid';

const CompObstaclesList = () => {
  const navigate = useNavigate();
  const {
    competition: { obstacles },
    competitionId
  } = useOutletContext();
  const queryClient = useQueryClient();

  const isEmpty = obstacles.length === 0;

  const updateCompetition = async (competitionData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { competitionData } });

    return await axios
      .put(`/api/competitions/${competitionId}`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const deleteObstacle = (name) => {
    const updatedObs = obstacles.filter((obstacle) => obstacle.name !== name);

    return updatedObs.map((o, i) => ({
      ...o,
      number: i + 1
    }));
  };

  const generateCompData = (obName) => ({
    obstacles: deleteObstacle(obName)
  });

  const handleDelete = async (obName) => {
    try {
      const compData = generateCompData(obName);

      const { data } = await updateCompetition(compData);

      if (data.success) {
        notifySuccess('Obstacle deleted');
        queryClient.invalidateQueries('competition');
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <>
      <h2 className='text-center text-lg text-red uppercase'>
        Competition Obstacles
      </h2>
      <ul className='mt-2 grid grid-cols-1 gap-3 sm:gap-6 xl:grid-cols-2 rounded'>
        {!isEmpty ? (
          obstacles.map(({ number, name, bonusPoints }) => (
            <li
              key={number}
              className='col-span-1 h-16 flex shadow-sm rounded-md'
            >
              <div
                className={
                  'flex-shrink-0 flex items-center justify-center w-16 border-t border-r border-b border-gray-200 bg-white font-medium rounded-l-md'
                }
              >
                <span className='flex items-center justify-center h-8 w-8 rounded-full text-white bg-gray-700'>
                  {number}
                </span>
              </div>
              <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md'>
                <div className='sm:w-40 px-2 py-4 text-sm'>
                  <span className='text-gray-800 font-medium uppercase'>
                    {name}
                  </span>
                </div>
                <div className='mx-1 px-2 py-1 text-white text-xs rounded-md bg-indigo-500 hover:bg-indigo-600'>
                  Bonus: <span className='font-bold'>{bonusPoints}</span>
                </div>
                <div className='justify-end flex gap-2 mr-3'>
                  <div className='flex'>
                    <button
                      onClick={() =>
                        navigate(
                          `/competitions/${competitionId}/obstacles/edit`,
                          { state: { obstacleNumber: number } }
                        )
                      }
                      className='text-blue-600 transition-transform hover:scale-125'
                    >
                      <PencilAltIcon className='w-6 h-6' />
                    </button>
                  </div>

                  <div className='flex'>
                    <ConfirmDelete
                      submitDelete={() => handleDelete(name)}
                      title='Delete Obstacle'
                    />
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li className='col-span-1 px-4 py-5 bg-white shadow-sm rounded-md'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
              No Obstacles Added
            </h3>
          </li>
        )}
      </ul>
      <Link
        to={`/competitions/${competitionId}/obstacles/add`}
        className='w-full md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Add Obstacle'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600'
        >
          <PlusIcon className='h-5 w-5' />
          <span className='uppercase'>Add Obstacle</span>
        </button>
      </Link>
    </>
  );
};

export default CompObstaclesList;
