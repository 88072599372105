import { useState } from 'react';

import { Combobox } from '@headlessui/react';

import { CheckIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/classNames';
import { useSelector } from 'react-redux';

const AthleteSelect = ({ athletes, selectedAthlete, setSelectedAthlete }) => {
  const [query, setQuery] = useState('');

  const finishedAthletes = useSelector(
    (state) => state.competition.finishedAthletes
  );

  const showIcon = query === '' && !selectedAthlete;

  const fullName = (athlete) =>
    athlete ? `${athlete.firstName} ${athlete.lastName}` : '';

  let filteredAthletes = [];

  if (athletes) {
    const available = athletes.filter(
      (athlete) => !finishedAthletes.includes(athlete.id)
    );

    const filtered =
      query === '' && athletes
        ? available
        : available.filter((athlete) => {
            return fullName(athlete)
              .toLowerCase()
              .includes(query.toLowerCase());
          });
    filteredAthletes = filtered.slice(0, 8);
  }

  return (
    <div className='mb-1 w-full flex flex-col md:flex-row gap-3.5'>
      <Combobox
        as='div'
        className='flex-1'
        value={selectedAthlete}
        onChange={setSelectedAthlete}
      >
        <Combobox.Label className='block text-sm font-medium text-red'>
          Search/Select Athlete
        </Combobox.Label>
        <div className='relative'>
          {showIcon && (
            <SearchIcon
              className='pointer-events-none absolute top-3 left-4 h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          )}
          <Combobox.Input
            className='w-full rounded-md text-white bg-blue-900/20 py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(athlete) => fullName(athlete)}
          />
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
            <SelectorIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </Combobox.Button>

          {filteredAthletes.length > 0 && (
            <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-600 py-1 text-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {filteredAthletes.map((athlete) => (
                <Combobox.Option
                  key={athlete.id}
                  value={athlete}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : ''
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          'block truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {fullName(athlete)}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
};

export default AthleteSelect;
