import { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { Combobox } from '@headlessui/react';

import { CheckIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/classNames';

const OfficialSearch = ({ isAlreadyOfficial, submitOfficial }) => {
  const [selectedOfficial, setSelectedOfficial] = useState('');

  const [query, setQuery] = useState('');

  const showIcon = query === '' && !selectedOfficial;

  const getOfficials = async () => {
    const { data } = await axios.get('/api/users/officials');
    return data;
  };

  const useOfficials = () => useQuery('officialsSearch', getOfficials);

  const { data, error } = useOfficials();

  let filteredOfficials = [];

  if (data) {
    const available = data.users.filter(
      (official) => !isAlreadyOfficial(official.email)
    );
    const filtered =
      query === '' && data
        ? available
        : available.filter((official) => {
            const fullName = `${official.firstName} ${official.lastName}`;
            return fullName.toLowerCase().includes(query.toLowerCase());
          });
    filteredOfficials = filtered.slice(0, 4);
  }

  const handleSubmitOfficial = async (selected) => {
    await submitOfficial({ id: selected._id });
  };

  return (
    <>
      {error && <div>Error: {error}</div>}
      {data && (
        <div className='w-full mb-4'>
          <h2 className='text-center text-lg text-red uppercase'>
            Find Existing Official
          </h2>
          <Combobox
            as='div'
            className='flex-1 mb-2'
            value={selectedOfficial}
            onChange={setSelectedOfficial}
          >
            <Combobox.Label className='block text-sm font-medium text-gray-200'>
              Search
            </Combobox.Label>
            <div className='relative'>
              {showIcon && (
                <SearchIcon
                  className='pointer-events-none absolute top-3 left-4 h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              )}
              <Combobox.Input
                className='w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(official) => {
                  return official
                    ? `${official.firstName} ${official.lastName}`
                    : '';
                }}
              />
              <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                <SelectorIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </Combobox.Button>

              {filteredOfficials.length > 0 && (
                <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {filteredOfficials.map((official) => (
                    <Combobox.Option
                      key={official._id}
                      value={official}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              selected && 'font-semibold'
                            )}
                          >
                            {`${official.firstName} ${official.lastName}`}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                              )}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>

          <button
            type='button'
            className='w-full md:self-end flex justify-center gap-1 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 '
            onClick={(e) => handleSubmitOfficial(selectedOfficial)}
            disabled={!selectedOfficial}
          >
            <CheckIcon className='h-5 w-5' />
            <div className='uppercase'>Add Official to Competition</div>
          </button>
        </div>
      )}
    </>
  );
};

export default OfficialSearch;
