import Footer from 'components/Footer';

import LargeLogo from 'assets/IXGL_GlobeLogo_2.jpg';

const Splash = () => {
  return (
    <div className='h-main flex flex-col justify-between items-center'>
      <main className='w-full grow bg-primary'>
        <img
          src={LargeLogo}
          alt='IXGL Logo'
          className='w-3/4 sm:w-1/2 lg:w-5/12 mx-auto'
        />
      </main>
      <Footer />
    </div>
  );
};

export default Splash;
