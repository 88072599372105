import { Tab } from '@headlessui/react';

const OfficialsPanel = ({ officials, isOfficial }) => {
  const isEmpty = officials.length < 1;

  return (
    <Tab.Panel>
      <ul className='mt-3 grid grid-cols-1 gap-3 sm:gap-6 md:grid-cols-2 rounded'>
        {!isEmpty ? (
          officials.map(({ _id, firstName, lastName, email }) => (
            <li key={_id} className='col-span-1 h-16 flex shadow-sm rounded-md'>
              <div className='w-full flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-md'>
                <div className='flex-1 px-4 py-2'>
                  <div className='text-gray-900 font-medium '>
                    {`${firstName} ${lastName}`}
                  </div>
                </div>
                {isOfficial && (
                  <div className='flex-1 flex flex-col md:flex-row md:items-baseline md:justify-between gap-x-2 mr-3'>
                    <div className='text-blue-800'>{email}</div>
                  </div>
                )}
              </div>
            </li>
          ))
        ) : (
          <li className='md:col-start-1 md:col-end-3 h-16 px-4 py-5 bg-white shadow-sm rounded-md'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
              No Officials Added
            </h3>
          </li>
        )}
      </ul>
    </Tab.Panel>
  );
};

export default OfficialsPanel;
