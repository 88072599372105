import { toast } from 'react-toastify';

export const notifySuccess = (message) =>
  toast.success(message, { theme: 'dark' });

export const notifyError = (message) => toast.error(message, { theme: 'dark' });

export const notifyInfo = (message) => toast.info(message, { theme: 'dark' });

export const notifyWarning = (message) =>
  toast.warn(message, { theme: 'dark' });

export const loadingToast = (message) => toast.loading(message);

export const successToastUpdate = (id, message) =>
  toast.update(id, {
    render: message,
    type: 'success',
    isLoading: false,
    autoClose: 3000
  });

export const errorToastUpdate = (id, message) =>
  toast.update(id, {
    render: message,
    type: 'error',
    isLoading: false,
    autoClose: 3000
  });
