import ConfirmDelete from 'components/ConfirmDelete';

export default function AthletesList({ athletes, deleteAthlete }) {
  return (
    <div>
      <ul className='mt-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4'>
        {athletes.map((athlete) => (
          <li
            key={athlete.id}
            className='col-span-2 h-16 flex sm:mr-0.5 shadow-sm rounded-md'
          >
            <div className='w-full flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-md'>
              <div className='flex-1 px-4 py-2 text-sm'>
                <div className='text-gray-900 font-medium'>
                  {`${athlete.firstName} ${athlete.lastName}`}
                </div>
                <p className=''>
                  <span className='text-blue-800'>{athlete.ageGroup}</span>
                  <span className='pl-2 text-xs uppercase text-gray-500'>
                    {athlete.division}
                  </span>
                </p>
              </div>
              <div className='flex justify-end mr-3'>
                <ConfirmDelete
                  submitDelete={() => deleteAthlete(athlete.id)}
                  title='Delete Athlete'
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
