import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Four04 from 'components/Four04';

const Athlete = () => {
  const { id } = useParams();
  const [fetchError, setFetchError] = useState(null);

  const getAthlete = async () => {
    try {
      const {
        data: { athlete }
      } = await axios.get(`/api/athletes/${id}`);

      return athlete;
    } catch (error) {
      console.log('error: ', error);
      setFetchError(error);
    }
  };
  const useAthlete = () => useQuery('athlete', getAthlete);

  const { data, error, isFetching } = useAthlete();

  return (
    <div className='h-full overflow-y-auto flex flex-col px-2 sm:px-24 xl:px-2 pt-4 md:py-8'>
      {isFetching && <Loader />}
      {data && <Outlet context={{ athlete: data, athleteId: id }} />}
      {fetchError && <Four04 />}
      {error && <div>Error: {error}</div>}

      <div className='-mx-2 my-2'>
        <Footer />
      </div>
    </div>
  );
};

export default Athlete;
