import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import {
  ArrowCircleRightIcon,
  DotsVerticalIcon,
  PencilIcon,
  PlayIcon,
  StarIcon
} from '@heroicons/react/solid';

import ConfirmDeleteComp from './ConfirmDeleteComp';

import { classNames } from 'utils/classNames';

const CompetitionMenu = ({
  competitionId,
  competition,
  isReady,
  userIsAdmin,
  handleStartCompetition
}) => {
  const showEditBtns = competition.status !== 'completed';
  const showScoresBtn = competition.status !== 'upcoming';
  const showStartBtn = competition.status === 'upcoming' && isReady;
  const showTrackerBtn = competition.status === 'in progress';

  return (
    <Menu as='div' className='relative inline-block -mt-8 text-left'>
      <div>
        <Menu.Button className='p-2 ml-auto bg-gray-800 rounded-full flex items-center text-gray-200 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
          <span className='sr-only'>Open options</span>
          <DotsVerticalIcon className='h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transform transition ease-in-out duration-500 sm:duration-700'
        enterFrom='translate-x-full'
        enterTo='translate-x-0'
        leave='transform transition ease-in-out duration-500 sm:duration-700'
        leaveFrom='translate-x-0'
        leaveTo='translate-x-full'
      >
        <Menu.Items className='origin-top-right absolute z-50 right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-600 text-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='p-2'>
            {showStartBtn && (
              <Menu.Item>
                <button
                  title='Start Competition'
                  className={classNames(
                    'w-full md:max-w-max inline-flex justify-center items-center py-2 border border-transparent rounded-md shadow-sm font-medium text-sm uppercase text-white  bg-green-600 hover:bg-green-700'
                  )}
                  onClick={handleStartCompetition}
                >
                  <PlayIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                  <span>Start Competition</span>
                </button>
              </Menu.Item>
            )}

            {showTrackerBtn && (
              <Link
                to={`/competitions/${competitionId}/tracker`}
                state={{ competition }}
              >
                <button
                  title='View Competition Tracker'
                  className='w-full inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm font-medium text-sm text-white uppercase bg-green-600 hover:bg-green-800'
                >
                  <ArrowCircleRightIcon
                    className='h-5 w-5 mr-2'
                    aria-hidden='true'
                  />
                  Competition Tracker
                </button>
              </Link>
            )}

            {showEditBtns && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active && 'bg-gray-800 font-medium',
                        'flex items-center justify-start px-4 py-2'
                      )}
                      to={`/competitions/${competitionId}/obstacles`}
                    >
                      <PencilIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                      Edit Obstacles
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active && 'bg-gray-800 font-medium',
                        'flex items-center justify-start px-4 py-2 border-t border-gray-700'
                      )}
                      to={`/competitions/${competitionId}/athletes`}
                    >
                      <PencilIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                      Edit Athletes
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active && 'bg-gray-800 font-medium',
                        'flex items-center justify-start px-4 py-2 border-t border-gray-700'
                      )}
                      to={`/competitions/${competitionId}/officials`}
                    >
                      <PencilIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                      Edit Officials
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/competitions/${competitionId}/edit`}
                      className={classNames(
                        active && 'bg-gray-800 font-medium',
                        'flex items-center justify-start px-4 py-2 border-t border-gray-700'
                      )}
                    >
                      <PencilIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                      Edit Competition
                    </Link>
                  )}
                </Menu.Item>
              </>
            )}

            {showScoresBtn && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/competitions/${competitionId}/scores`}
                    className={classNames(
                      active && 'bg-gray-800 font-medium',
                      'flex items-center justify-start px-4 py-2 border-t border-gray-700'
                    )}
                  >
                    <StarIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                    View Scores
                  </Link>
                )}
              </Menu.Item>
            )}

            {!showEditBtns && userIsAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/competitions/${competitionId}/scores/edit`}
                    className={classNames(
                      active && 'bg-gray-800 font-medium',
                      'flex items-center justify-start px-4 py-2 border-t border-gray-700'
                    )}
                  >
                    <PencilIcon className='h-5 w-5 mr-2' aria-hidden='true' />
                    Edit Scores
                  </Link>
                )}
              </Menu.Item>
            )}

            {userIsAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <ConfirmDeleteComp
                    competitionId={competitionId}
                    isCompleted={competition.status === 'completed'}
                    inProgress={competition.status === 'in progress'}
                  />
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CompetitionMenu;
