import {
  INCREMENT_FAIL_COUNT,
  SET_FAIL_COUNT,
  SET_FINISHED,
  SET_TOTAL_TIME,
  START_NEXT_OBSTACLE,
  UPDATE_TOTAL_POINTS
} from './types';

// updates total time used by athlete
// @param time: number (ms)
export const updateTotalTime = (time) => ({
  type: SET_TOTAL_TIME,
  payload: time
});

// increments fail count by 1
export const incrementFailCount = () => ({
  type: INCREMENT_FAIL_COUNT
});

// sets fail count to a specific value
export const setFailCount = (count) => ({
  type: SET_FAIL_COUNT,
  payload: count
});

// updates current obstacle number
// @param obstacleNum: next obstacle number
export const startNextObstacle = (obstacleNum) => ({
  type: START_NEXT_OBSTACLE,
  payload: obstacleNum
});

export const updateTotalPoints = (points) => ({
  type: UPDATE_TOTAL_POINTS,
  payload: points
});

// sets athlete to finished status
export const setFinished = (bool) => ({
  type: SET_FINISHED,
  payload: bool
});
