import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { checkIsUserCompetitionAuthorized } from 'utils/officials';
import { notifyWarning } from 'utils/toast';

const OfficialsOnly = ({ children, competition }) => {
  let location = useLocation();
  const { loggedIn, user } = useSelector((state) => state.user);

  if (!loggedIn) {
    return <Navigate to={'/signin'} state={{ from: location }} replace />;
  }

  const isOfficial =
    loggedIn && checkIsUserCompetitionAuthorized(user, competition);
  // console.log('isOfficial: ', isOfficial);

  if (!isOfficial) {
    notifyWarning('You are not authorized');
    return <Navigate to={`/competitions/${competition._id}`} replace />;
  }

  return children;
};

export default OfficialsOnly;
