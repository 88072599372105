import { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationIcon,
  TrashIcon,
  XIcon
} from '@heroicons/react/outline';

import {
  loadingToast,
  successToastUpdate,
  errorToastUpdate
} from 'utils/toast';

const ConfirmDeleteComp = ({ competitionId, isCompleted, inProgress }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isVisible, setIsVisible] = useState(false);
  const deleteToast = useRef(null);
  const handleConfirm = async () => {
    await handleDelete();
  };

  const deleteCompetition = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    deleteToast.current = loadingToast('Deleting competition...');

    return await axios
      .delete(`/api/competitions/${competitionId}`, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const handleDelete = async () => {
    try {
      const res = await deleteCompetition();

      if (res.data.success) {
        successToastUpdate(deleteToast.current, res.data.message);

        queryClient.invalidateQueries('competitions');

        navigate(`/competitions`);
      } else {
        const message = res.data.message || 'Error deleting competition';
        errorToastUpdate(deleteToast.current, message);
      }
    } catch (error) {
      errorToastUpdate(deleteToast.current, 'Error deleting competition');
    }
  };

  return (
    <>
      <button
        id={`confirmActionModalBtn`}
        title={`Confirm Delete Competition`}
        onClick={() => setIsVisible(true)}
        className='flex items-center justify-start px-4 py-2 border-t border-gray-700 hover:bg-gray-800 hover:font-medium hover:text-red-600'
      >
        <TrashIcon className='w-5 h-5 mr-2' />
        Delete Competition
      </button>
      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-[100] inset-0 overflow-y-auto'
          onClose={setIsVisible}
        >
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-80 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-gray-700 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => setIsVisible(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationIcon
                      className='h-6 w-6 text-red-600'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-white'
                    >
                      Delete Competition?
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-white'>
                        Are you sure you want to delete this competition?
                      </p>
                      {isCompleted && (
                        <p className='text-sm text-white'>
                          This competition has already been COMPLETED. Deleting
                          it will remove the results from all participating
                          athletes. This will affect the leaderboard results
                          forever. THIS CANNOT BE UNDONE.
                        </p>
                      )}
                      {inProgress && (
                        <p className='text-sm text-white'>
                          This competition is IN PROGRESS. Deleting it will
                          remove the results from all participating athletes.
                          THIS CANNOT BE UNDONE.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    type='button'
                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 uppercase text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => handleConfirm()}
                  >
                    <CheckIcon className='h-5 w-5 mr-1' aria-hidden='true' />
                    Delete
                  </button>
                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 uppercase text-base font-medium text-indigo-700 hover:text-white bg-indigo-100 hover:bg-indigo-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                    onClick={() => setIsVisible(false)}
                  >
                    <XIcon className='h-5 w-5 mr-1' aria-hidden='true' />
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ConfirmDeleteComp;
