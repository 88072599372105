import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';

import CompetitionHeader from 'components/CompetitionHeader';
import CompAthleteAdd from './CompAthleteAdd';
import AthletesList from './AthletesList';

import { notifyError, notifyInfo, notifySuccess } from 'utils/toast';

import { PlusIcon } from '@heroicons/react/solid';

const CompAthletesList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const { competition, competitionId } = useOutletContext();

  const { athletes } = competition;
  // console.log('athletes: ', athletes);

  const checkIfRegistered = (athleteId) => {
    const result = athletes.find((athlete) => athlete.id === athleteId);
    return result;
  };

  const deleteAthlete = async (athleteId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return await axios
      .delete(
        `/api/competitions/${competitionId}/athletes/${athleteId}`,
        config
      )
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitDeleteAthlete = async (athleteId) => {
    try {
      if (!isLoggedIn) {
        notifyInfo('Please login to register an athlete');
        navigate('/signin');
      } else {
        const { data } = await deleteAthlete(athleteId);

        if (data.success) {
          notifySuccess('Athlete removed from competition');

          queryClient.invalidateQueries('competition');
        } else {
          notifyError(data.message);
        }
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <>
      <div className='relative'>
        <CompetitionHeader
          competition={competition}
          competitionId={competitionId}
        />

        <div className='w-full lg:max-w-3xl lg:mx-auto -mt-4 md:mt-0 px-2 sm:px-0'>
          <CompAthleteAdd
            competitionId={competitionId}
            checkIfRegistered={checkIfRegistered}
          />

          <Link
            to={`/competitions/${competitionId}/athletes/add`}
            className='w-full mt-4 sm:px-4 md:mr-4 md:w-auto inline-flex items-center'
          >
            <button
              type='button'
              title='Create New Athlete'
              className='w-full flex justify-center gap-1 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600'
            >
              <PlusIcon className='h-5 w-5' />
              <span className='uppercase'>Create New Athlete</span>
            </button>
          </Link>

          <div className='mt-2 py-2 align-middle inline-block min-w-full sm:px-4'>
            <h2 className='text-center text-lg text-red uppercase'>
              Registered Athletes
            </h2>
            <div className='sm:rounded-lg'>
              {athletes.length > 0 ? (
                <AthletesList
                  athletes={athletes}
                  deleteAthlete={submitDeleteAthlete}
                />
              ) : (
                <div className='px-4 py-5 bg-white shadow rounded-lg sm:p-6'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
                    No Athletes Registered
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompAthletesList;
