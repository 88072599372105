import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ChevronRightIcon,
  SearchIcon,
  XCircleIcon
} from '@heroicons/react/solid';

export default function AthleteItems({ athletes }) {
  const [query, setQuery] = useState('');

  const queryEmpty = query === '';

  const results =
    query === ''
      ? athletes
      : athletes.filter((athlete) =>
          athlete.fullName.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <div className='shadow overflow-hidden'>
      <div className='mb-4'>
        <label
          htmlFor='search'
          className='block text-sm font-medium text-gray-300'
        >
          Quick search
        </label>
        <div className='mt-1 relative flex items-center'>
          <SearchIcon
            className='pointer-events-none absolute top-3 left-2 h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
          <input
            type='text'
            name='search'
            id='search'
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 border-gray-300 rounded-md'
          />
          {!queryEmpty && (
            <div className='absolute inset-y-0 right-0 flex items-center  pr-1.5'>
              <XCircleIcon
                className='cursor-pointer text-gray-400 h-5 w-5'
                aria-hidden='true'
                onClick={() => setQuery('')}
              />
            </div>
          )}
        </div>
      </div>
      <ul className='divide-y divide-gray-200 space-y-4'>
        {results.map((athlete) => (
          <li
            key={athlete._id}
            className='bg-white hover:bg-indigo-50 rounded-md'
          >
            <Link to={`/athletes/${athlete._id}`} className='block '>
              <div className='px-4 py-3 flex items-center sm:px-6'>
                <div className='min-w-0 flex-1 flex items-center justify-between'>
                  <div className='flex flex-col md:flex-row md:items-center'>
                    <div className='sm:w-48'>
                      <div className='font-medium text-base text-indigo-600'>
                        {athlete.fullName}
                      </div>
                    </div>
                    <div className='md:ml-3 md:w-48 font-normal text-sm text-gray-500'>
                      <div>
                        {athlete.city},{' '}
                        {athlete.state ? `${athlete.state}, ` : ''}{' '}
                      </div>
                      <div>{athlete.country}</div>
                    </div>
                  </div>
                  <div className='w-32 flex flex-col'>
                    <div className='sm:w-40 text-sm text-gray-500'>
                      {athlete.ageGroup}
                    </div>
                    <div className='text-sm text-gray-500 uppercase'>
                      {athlete.division}
                    </div>
                  </div>
                </div>
                <div className='ml-3'>
                  <ChevronRightIcon
                    className='h-7 w-7 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
