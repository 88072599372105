import {
  START_STOPWATCH,
  PAUSE_STOPWATCH,
  RESUME_STOPWATCH,
  RESET_STOPWATCH,
  UPDATE_STOPWATCH_TIME
} from './types';

export const stopwatchInitialState = {
  totalTime: 0,
  lastTotal: 0,
  startTime: 0,
  isStopwatchRunning: false,
  isStopwatchPaused: false
};

const reducer = (state = stopwatchInitialState, action) => {
  switch (action.type) {
    case START_STOPWATCH:
      return {
        ...state,
        totalTime: 0,
        lastTotal: 0,
        startTime: action.payload,
        isStopwatchRunning: true,
        isStopwatchPaused: false
      };

    case PAUSE_STOPWATCH:
      return {
        ...state,
        totalTime: state.lastTotal + action.payload,
        lastTotal: state.lastTotal + action.payload,
        isStopwatchRunning: false,
        isStopwatchPaused: true
      };

    case RESUME_STOPWATCH:
      return {
        ...state,
        startTime: action.payload,
        isStopwatchRunning: true,
        isStopwatchPaused: false
      };

    case RESET_STOPWATCH:
      return {
        ...state,
        totalTime: 0,
        lastTotal: 0,
        startTime: 0,
        isStopwatchRunning: false,
        isStopwatchPaused: false
      };

    case UPDATE_STOPWATCH_TIME:
      return {
        ...state,
        totalTime: state.lastTotal + action.payload
      };

    default:
      return state;
  }
};

export default reducer;
