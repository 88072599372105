export const INCREMENT_FAIL_COUNT = 'INCREMENT_FAIL_COUNT';
export const INITIALIZE_TRACKER = 'INITIALIZE_TRACKER';
export const RESET_ATHLETE = 'RESET_ATHLETE';
export const RESET_TRACKER = 'RESET_TRACKER';
export const RETRY_OBSTACLE = 'RETRY_OBSTACLE';
export const SET_FAIL_COUNT = 'SET_FAIL_COUNT';
export const SET_FINISHED = 'SET_FINISHED';
export const SET_ON_OBSTACLE = 'SET_ON_OBSTACLE';
export const SET_TOTAL_TIME = 'SET_TOTAL_TIME';
export const START_NEXT_OBSTACLE = 'START_NEXT_OBSTACLE';
export const UPDATE_OBSTACLES = 'UPDATE_OBSTACLES';
export const UPDATE_TOTAL_POINTS = 'UPDATE_TOTAL_POINTS';
