import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Disclosure } from '@headlessui/react';

import HeaderMenu from 'components/HeaderMenu';

import { MenuIcon } from 'icons/MenuIcon';
import { XIcon } from 'icons/XIcon';
import Logo from 'assets/ixgl-logo-round.png';

const Header = () => {
  const { loggedIn, user } = useSelector((state) => state.user);

  const isAdmin = user?.role === 'admin';

  const navLinkActiveClasses = (isActive) =>
    `${
      isActive
        ? 'text-red border-red'
        : 'text-gray-300 border-transparent hover:text-red transform hover:scale-125'
    } bg-transparent border-b-2 px-3 py-2 text-sm font-medium uppercase transition ease-in`;

  return (
    <Disclosure as='nav' className='bg-gray-800'>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between h-16'>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
                  <NavLink
                    to={'/'}
                    className='flex-shrink-0 flex items-center w-auto hover:scale-125  tracking-widest'
                  >
                    <img className='block h-16 w-auto' src={Logo} alt='IXGL' />
                    <span className='hidden md:block text-red text-lg font-bold ml-2'>
                      IXGL
                    </span>
                  </NavLink>
                </div>
                <div className='hidden sm:block sm:ml-6'>
                  <div className='flex space-x-4'>
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    <NavLink
                      to='/leaderboards'
                      className={({ isActive }) =>
                        navLinkActiveClasses(isActive)
                      }
                    >
                      Leaderboards
                    </NavLink>
                    <NavLink
                      to='/competitions'
                      className={({ isActive }) =>
                        navLinkActiveClasses(isActive)
                      }
                    >
                      Competitions
                    </NavLink>
                    <NavLink
                      to='/athletes'
                      className={({ isActive }) =>
                        navLinkActiveClasses(isActive)
                      }
                    >
                      Athletes
                    </NavLink>

                    {isAdmin && (
                      <NavLink
                        to='/users'
                        className={({ isActive }) =>
                          navLinkActiveClasses(isActive)
                        }
                      >
                        Users
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
              <div className='hidden sm:ml-6 sm:block'>
                <div className='flex items-center'>
                  <HeaderMenu />
                </div>
              </div>
              <div className='-mr-2 flex sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon
                      classes='block h-6 w-6 text-gray-400'
                      aria-hidden='true'
                    />
                  ) : (
                    <MenuIcon
                      classes='block h-6 w-6 text-gray-400'
                      aria-hidden='true'
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button className='text-gray-300 block px-3 py-2 rounded-md text-base font-medium'>
                <NavLink to='/leaderboards'>Leaderboards</NavLink>
              </Disclosure.Button>
              <Disclosure.Button className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
                <NavLink to='/competitions'>Competitions</NavLink>
              </Disclosure.Button>
              <Disclosure.Button className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
                <NavLink to='/athletes'>Athletes</NavLink>
              </Disclosure.Button>
              {isAdmin && (
                <Disclosure.Button className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
                  <NavLink to='/users'>Users</NavLink>
                </Disclosure.Button>
              )}
            </div>
            <div className='pt-4 pb-3 border-t border-gray-700'>
              <div className='mt-3 px-2 space-y-1'>
                {loggedIn && (
                  <div className='block px-3 py-2 rounded-md text-base font-medium text-white'>
                    {user.email}
                  </div>
                )}
                <Disclosure.Button className='block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700'>
                  <Link to='/support'>Support</Link>
                </Disclosure.Button>
                {/* 
                <Disclosure.Button className='block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700'>
                  Settings
                </Disclosure.Button> */}
                <Disclosure.Button className='block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700'>
                  {loggedIn ? (
                    <Link to='/signout'>Sign out</Link>
                  ) : (
                    <Link to='/signin'>Sign in</Link>
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
