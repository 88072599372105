import { useDispatch, useSelector } from 'react-redux';

import { Transition } from '@headlessui/react';

import { countdownActions } from 'store/countdown';
import { stopwatchActions } from 'store/stopwatch';
import { trackerOperations } from 'store/tracker';

import { classNames } from 'utils/classNames';

import RetryIcon from 'icons/RetryIcon';
import { CheckIcon, MinusCircleIcon } from '@heroicons/react/solid';

const { startRestCountdown } = countdownActions;
const { pauseStopwatch } = stopwatchActions;
const { markObstacleClear, markObstacleFail, retryObstacle } =
  trackerOperations;

const ObstacleControls = () => {
  const dispatch = useDispatch();
  const {
    currentObstacleNum,
    lastObstacleNum,
    onObstacle,
    retryCount,
    isFinished
  } = useSelector((state) => state.tracker);

  const handleClear = async () => {
    dispatch(pauseStopwatch());
    dispatch(markObstacleClear(currentObstacleNum));
    if (currentObstacleNum !== lastObstacleNum) {
      dispatch(startRestCountdown());
    }
  };
  const handleFail = () => {
    dispatch(pauseStopwatch());
    dispatch(markObstacleFail(currentObstacleNum));
    if (currentObstacleNum !== lastObstacleNum) {
      dispatch(startRestCountdown());
    }
  };
  const handleRetry = () => {
    dispatch(retryObstacle(currentObstacleNum));
  };

  return (
    !isFinished && (
      <Transition
        show={onObstacle}
        enter='transition duration-100 delay-75 ease-out'
        enterFrom='transform scale-0 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-100 ease-in'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-0 opacity-0'
      >
        <div className='max-w-4xl mx-auto mb-2 shadow-sm rounded-md border border-gray-500 bg-white'>
          <div className='relative w-full z-0 inline-flex shadow-sm rounded-md'>
            <button
              type='button'
              onClick={() => handleClear()}
              disabled={!onObstacle}
              className={classNames(
                'relative flex-1 inline-flex items-center justify-center px-4 py-2 rounded-l-md border border-transparent text-white bg-green-600 hover:bg-green-700'
              )}
            >
              <CheckIcon className='h-6 w-6' />
              <span className='ml-1 font-medium uppercase tracking-wider'>
                Clear
              </span>
            </button>
            <button
              type='button'
              onClick={() => handleFail()}
              disabled={!onObstacle}
              className={classNames(
                '-ml-px relative flex-1 inline-flex items-center justify-center px-4 py-2 border  border-transparent text-white bg-red-600 hover:bg-red-700'
              )}
            >
              <MinusCircleIcon className='h-6 w-6' />
              <span className='ml-1 font-medium uppercase tracking-wider'>
                Fail
              </span>
            </button>
            <button
              type='button'
              onClick={() => handleRetry()}
              disabled={retryCount >= 3}
              className='btn-pulse-click -ml-px relative flex-1 inline-flex items-center justify-center px-4 py-2 rounded-r-md border border-transparent  text-white bg-indigo-600 hover:bg-indigo-700'
            >
              <RetryIcon className='h-6 w-6' />
              <span className='ml-1 font-medium first-line:uppercase tracking-wider'>
                Retry
              </span>
            </button>
          </div>
        </div>
      </Transition>
    )
  );
};

export default ObstacleControls;
