import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import OfficialSearch from './OfficialSearch';
import OfficialForm from './OfficialForm';

import { notifyError, notifySuccess } from 'utils/toast';

import { XIcon } from '@heroicons/react/outline';

const CompOfficialAdd = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { competition, competitionId } = useOutletContext();

  const checkIfOfficialByEmail = (email) => {
    const result = competition.officials.find(
      (official) => official.email.toLowerCase() === email.toLowerCase()
    );
    return result;
  };

  const addOfficial = async (officialData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { officialData } });

    return await axios
      .put(`/api/competitions/${competitionId}/officials`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitOfficial = async (submitData) => {
    try {
      const { data } = await addOfficial(submitData);

      if (data.success) {
        notifySuccess('Official added');
        queryClient.invalidateQueries('competition');

        navigate(`/competitions/${competitionId}/officials`);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='sm:px-4 mb-4'>
      <OfficialSearch
        isAlreadyOfficial={checkIfOfficialByEmail}
        submitOfficial={submitOfficial}
      />

      <OfficialForm
        isAlreadyOfficial={checkIfOfficialByEmail}
        submitOfficial={submitOfficial}
      />

      <Link
        to={`/competitions/${competitionId}/officials`}
        className='w-full mt-4 md:mt-2 md:mr-4 md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Cancel'
          className='w-full flex justify-center gap-1 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <XIcon className='h-5 w-5' aria-hidden='true' />
          <span className='uppercase'>Cancel</span>
        </button>
      </Link>
    </div>
  );
};

export default CompOfficialAdd;
