import { Outlet, useOutletContext } from 'react-router-dom';

import OfficialsOnly from 'components/OfficialsOnly';
import CompetitionHeader from 'components/CompetitionHeader';

const CompOfficials = () => {
  const { competition, competitionId } = useOutletContext();

  return (
    <OfficialsOnly competition={competition}>
      <div className='relative flex flex-col items-center'>
        <CompetitionHeader
          competition={competition}
          competitionId={competitionId}
        />

        <div className='py-2 align-middle inline-block w-full sm:max-w-2xl lg:max-w-3xl sm:px-4'>
          <Outlet context={{ competition, competitionId }} />
        </div>
      </div>
    </OfficialsOnly>
  );
};

export default CompOfficials;
