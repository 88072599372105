import axios from 'axios';

import {
  SET_COMPETITION_DATA,
  SET_STATUS,
  UPDATE_FINISHED_ATHLETES
} from './types';

import { appActions } from '../app';
import { trackerOperations } from 'store/tracker';

const { setDataLoading } = appActions;
const { resetTracker } = trackerOperations;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const setCompetitionStatus = (status) => ({
  type: SET_STATUS,
  payload: status
});

export const updateFinishedAthletes = (athleteId) => ({
  type: UPDATE_FINISHED_ATHLETES,
  payload: athleteId
});

export const loadCompetitionData = (competition) => async (dispatch) => {
  const {
    _id,
    name,
    date,
    status,
    location,
    obstacles,
    athletes,
    officials,
    results
  } = competition;

  const finishedAthletes =
    results.length > 0 ? results.map((result) => result.athlete._id) : [];

  const trackerObstacles = obstacles.map((obstacle) => ({
    obstacleNumber: obstacle.number,
    name: obstacle.name,
    result: 'n/a',
    retries: 0,
    bonusPoints: obstacle.bonusPoints,
    bonusCompleted: false,
    timestamp: 0
  }));

  const payload = {
    id: _id,
    name,
    date,
    status,
    location,
    obstacles,
    athletes,
    officials,
    trackerObstacles,
    finishedAthletes
  };

  await dispatch({
    type: SET_COMPETITION_DATA,
    payload
  });
};

export const updateCompetitionStatus = async (competitionId, status) => {
  console.log('updateCompetitionStatus');
  let actionSuccess;

  try {
    const body = JSON.stringify({ data: { competitionStatus: status } });

    const response = await axios.put(
      `/api/competitions/${competitionId}/status`,
      body,
      config
    );

    actionSuccess = response.data.success;
  } catch (error) {
    console.log(error);
    actionSuccess = false;
  } finally {
    return { actionSuccess };
  }
};

export const updateAthleteResult = () => async (dispatch, getState) => {
  console.log('updateAthleteResult');
  let actionSuccess;

  try {
    await dispatch(setDataLoading(true));
    const { id, date } = getState().competition;
    const {
      currentAthlete,
      failCount,
      retryCount,
      obstacles,
      totalPoints,
      totalTime
    } = getState().tracker;

    const athleteResult = {
      athlete: currentAthlete.id,
      status: 'finished',
      division: currentAthlete.division,
      ageGroup: currentAthlete.ageGroup,
      date,
      totalTime: totalTime,
      points: totalPoints,
      failCount: failCount,
      retryCount: retryCount,
      obstacleResults: obstacles
    };

    const body = JSON.stringify({ data: { athleteResult } });

    const result = await axios.put(
      `/api/competitions/${id}/results/${currentAthlete.id}`,
      body,
      config
    );

    actionSuccess = result.data.success;

    if (actionSuccess) {
      await dispatch(updateFinishedAthletes(currentAthlete.id));
      await dispatch(resetTracker());
    }
  } catch (err) {
    console.log('update athlete error', err);
    actionSuccess = false;
  } finally {
    await dispatch(setDataLoading(false));
    return { actionSuccess };
  }
};

export const finalizeCompetition = () => async (dispatch, getState) => {
  try {
    const { id } = getState().competition;

    config.method = 'put';
    config.url = `/api/competitions/${id}/finalize`;

    const { data } = await axios(config);
    // console.log('finalize competition data: ', data);

    return {
      actionSuccess: data.success,
      competitionId: data.competition._id
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
