import {
  START_COUNTDOWN,
  UPDATE_COUNTDOWN_TIME,
  RESET_COUNTDOWN,
  SET_COUNTDOWN_EXPIRED
} from './types';

import { REST_TIME_MS } from 'store/constants';

export const countdownInitialState = {
  timeRemaining: REST_TIME_MS,
  startTime: 0,
  isCountdownRunning: false,
  isExpired: false
};

const reducer = (state = countdownInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_COUNTDOWN:
      return {
        ...state,
        startTime: payload,
        isCountdownRunning: true
      };

    case UPDATE_COUNTDOWN_TIME:
      return {
        ...state,
        timeRemaining: REST_TIME_MS - payload
      };

    case SET_COUNTDOWN_EXPIRED:
      return {
        ...state,
        isExpired: payload
      };

    case RESET_COUNTDOWN:
      return {
        ...state,
        ...countdownInitialState
      };

    default:
      return state;
  }
};

export default reducer;
