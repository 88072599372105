import RequireAuth from 'components/RequireAuth';

const AccountSettings = () => {
  return (
    <RequireAuth>
      <main className='flex flex-col items-center w-full flex-1 pt-10 md:px-20 mb-10 text-center'>
        <h1 className='text-3xl md:text-4xl font-bold mb-8 text-red'>
          Account Settings
        </h1>
      </main>
    </RequireAuth>
  );
};

export default AccountSettings;
