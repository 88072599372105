import { useRef } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import RequireAuth from 'components/RequireAuth';

import CountryOptions from '../CountryOptions';

import { formatToUtc, formatUtcDateTime } from 'utils/date';
import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const CompetitionEdit = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { competition, competitionId } = useOutletContext();
  const { name, date, hostGym, city, state, country } = competition;

  const { handleSubmit, register } = useForm({
    defaultValues: {
      name,
      // need to convert Z datetime to datetime-local
      // this format works with date picker: '2022-04-02T00:00:00'
      date: formatUtcDateTime(date),
      hostGym,
      city,
      state,
      country
    }
  });

  const formRef = useRef(null);

  const updateCompetition = async (competitionData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { competitionData } });

    return await axios
      .put(`/api/competitions/${competitionId}`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitEditCompetition = async (formData) => {
    try {
      const submitData = getTrimmedStringData(formData);
      const compDate = formatToUtc(submitData.date);

      const compData = {
        ...submitData,
        date: compDate
      };

      const { data } = await updateCompetition(compData);

      if (data.success) {
        notifySuccess('Competition updated');
        queryClient.invalidateQueries('competition');

        navigate(`/competitions/${competitionId}`);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <RequireAuth>
      <div className='relative lg:max-w-2xl lg:mx-auto rounded h-full pb-10'>
        <h1 className='text-center text-2xl md:text-3xl md:mb-8 text-red'>
          Edit Competition
        </h1>

        <form
          className='min-h-fit mt-4'
          ref={formRef}
          onSubmit={handleSubmit(submitEditCompetition)}
        >
          <div className='shadow sm:rounded-md'>
            <div className='grid grid-cols-6 gap-3 md:gap-4'>
              <div className='col-span-6'>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-gray-200'
                >
                  Competition Name
                </label>
                <input
                  type='text'
                  name='name'
                  id='name'
                  {...register('name')}
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='date'
                  className='block text-sm font-medium text-gray-200'
                >
                  Competition Date
                </label>
                <input
                  type='datetime-local'
                  name='date'
                  id='date'
                  {...register('date')}
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='hostGym'
                  className='block text-sm font-medium text-gray-200'
                >
                  Host Gym
                </label>
                <input
                  type='text'
                  name='hostGym'
                  id='hostGym'
                  {...register('hostGym')}
                  autoComplete='address-level2'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6'>
                <label
                  htmlFor='city'
                  className='block text-sm font-medium text-gray-200'
                >
                  City
                </label>
                <input
                  type='text'
                  name='city'
                  id='city'
                  {...register('city')}
                  autoComplete='address-level2'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='region'
                  className='block text-sm font-medium text-gray-200'
                >
                  State / Province
                </label>
                <input
                  type='text'
                  name='state'
                  id='state'
                  {...register('state')}
                  autoComplete='address-level1'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='country'
                  className='block text-sm font-medium text-gray-200'
                >
                  Country
                </label>
                <select
                  name='country'
                  id='country'
                  {...register('country')}
                  autoComplete='country-name'
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                  defaultValue={'Select a country'}
                >
                  <CountryOptions />
                </select>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <CheckIcon className='h-5 w-5' aria-hidden='true' />
            <div className='uppercase'>Update Competition</div>
          </button>
        </form>
        <Link
          to={`/competitions/${competitionId}`}
          className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
        >
          <button
            type='button'
            title='Cancel'
            className='w-full flex justify-center gap-1 my-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <XIcon className='h-5 w-5' aria-hidden='true' />
            <span className='uppercase'>Cancel</span>
          </button>
        </Link>
      </div>
    </RequireAuth>
  );
};

export default CompetitionEdit;
