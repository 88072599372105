import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import Footer from 'components/Footer';
import Loader from 'components/Loader';
import AthleteItems from './AthleteItems';

import { PlusIcon } from '@heroicons/react/solid';

const AthleteList = () => {
  const userRole = useSelector((state) => state.user.user.role);
  const isAuthorized = userRole === 'admin' || userRole === 'organizer';

  const getAthletes = async () => {
    const { data } = await axios.get('/api/athletes');

    return data;
  };
  const useAthletes = () => useQuery('athletes', getAthletes);

  const { data, error, isFetching } = useAthletes();

  return (
    <div className='h-full overflow-y-auto flex flex-col justify-between px-2 sm:px-24 xl:px-2 pt-4 md:pt-8'>
      <div className='grow'>
        <div className='flex align-middle justify-between mb-2'>
          <h1 className='ml-8 text-center text-3xl md:text-4xl font-bold text-red'>
            Athletes
          </h1>
          {isAuthorized && (
            <button
              type='button'
              title='Add New Athlete'
              className='inline-flex items-center mr-2 p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <Link to={`/athletes/new`} className='inline-flex items-center'>
                <PlusIcon className='h-7 w-7' aria-hidden='true' />
              </Link>
            </button>
          )}
        </div>

        {isFetching && !data ? (
          <Loader />
        ) : data && data.athletes?.length > 0 ? (
          <AthleteItems athletes={data.athletes} />
        ) : (
          <div className='col-span-2 px-4 py-5 bg-white shadow-sm rounded-md'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
              No Athletes Found
            </h3>
          </div>
        )}
        {error && <div>Error: {error.message}</div>}
      </div>
      <div className='-mx-2 my-2'>
        <Footer />
      </div>
    </div>
  );
};

export default AthleteList;
