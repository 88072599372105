import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import Loader from 'components/Loader';
import UserItems from './UserItems';
import AdminsOnly from 'components/AdminsOnly';

import { PlusIcon } from '@heroicons/react/solid';

const UserList = () => {
  const getUsers = async () => {
    const { data } = await axios.get('/api/users');

    return data;
  };
  const useUsers = () => useQuery('users', getUsers);

  const { data, error, isFetching } = useUsers();

  return (
    <AdminsOnly>
      <div className='flex align-middle justify-between mb-8'>
        <h1 className='ml-8 text-center text-3xl md:text-4xl font-bold text-red'>
          Users
        </h1>
        <button
          type='button'
          title='Add New Athlete'
          className='inline-flex items-center mr-2 p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <Link to={`/users/new`} className='inline-flex items-center'>
            <PlusIcon className='h-7 w-7' aria-hidden='true' />
          </Link>
        </button>
      </div>

      {isFetching && !data ? (
        <Loader />
      ) : data && data.users?.length > 0 ? (
        <UserItems users={data.users} />
      ) : (
        <div className='col-span-2 px-4 py-5 bg-white shadow-sm rounded-md'>
          <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
            No Users Found
          </h3>
        </div>
      )}
      {error && <div>Error: {error.message}</div>}
    </AdminsOnly>
  );
};

export default UserList;
