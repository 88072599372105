import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import Header from 'components/Header';

import { userActions } from 'store/user';

import 'styles/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';

const queryClient = new QueryClient();

const { authTokenLogin } = userActions;

function App() {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.authToken);
  const isLoggedIn = useSelector((state) => state.user.loggedIn);

  useEffect(() => {
    if (authToken && !isLoggedIn) {
      dispatch(authTokenLogin(authToken));
    }
  }, [authToken, dispatch, isLoggedIn]);

  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Outlet />
      <ToastContainer theme='dark' />
    </QueryClientProvider>
  );
}

export default App;
