import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import ConfirmDelete from 'components/ConfirmDelete';

import { notifyError, notifySuccess } from 'utils/toast';

import { PencilAltIcon } from '@heroicons/react/solid';

const UserItems = ({ users }) => {
  const navigate = useNavigate();
  const [userItems, setUserItems] = useState(users);

  const deleteUserItem = (userId) => {
    const newUserItems = userItems.filter((user) => user._id !== userId);
    setUserItems(newUserItems);
  };

  const deleteUser = async (userId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return await axios
      .delete(`/api/users/${userId}`, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitDeleteUser = async (userId) => {
    try {
      const { data } = await deleteUser(userId);

      if (data.success) {
        notifySuccess('User deleted');
        deleteUserItem(userId);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='shadow'>
      <ul className='divide-y divide-gray-200 space-y-4'>
        {users.map((user) => (
          <li key={user._id} className='bg-white rounded-md'>
            <div className='px-4 py-2 flex items-center sm:px-6 sm:py-3'>
              <div className='flex-1 flex flex-col md:flex-row justify-between'>
                <div className='flex flex-col md:items-center'>
                  <div className='sm:w-48'>
                    <div className='font-medium text-base text-indigo-600'>
                      {`${user.firstName} ${user.lastName}`}
                    </div>
                  </div>
                  <div className='md:w-48 font-normal text-sm text-gray-600'>
                    {user.email}
                  </div>
                </div>
                <div className='md:m-auto text-sm text-gray-600 uppercase'>
                  {user.role}
                </div>
              </div>
              <div className='mx-1.5 flex gap-x-3 sm:gap-x-4'>
                <button
                  onClick={() => navigate(`/users/${user._id}/edit`)}
                  className='text-blue-600 transition-transform hover:scale-125'
                  title='Edit User'
                >
                  <PencilAltIcon className='w-6 h-6' />
                </button>

                <ConfirmDelete
                  submitDelete={() => submitDeleteUser(user._id)}
                  title='Delete User'
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserItems;
