import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import Loader from 'components/Loader';
import Four04 from 'components/Four04/';

const Competition = () => {
  const { id } = useParams();

  const [fetchError, setFetchError] = useState(null);

  const getCompetition = async () => {
    try {
      const {
        data: { competition }
      } = await axios.get(`/api/competitions/${id}`);

      return competition;
    } catch (error) {
      console.log('error: ', error);
      setFetchError(error);
    }
  };
  const useCompetition = () => useQuery('competition', getCompetition);

  const { data, error, isFetching } = useCompetition();

  return (
    <div className='w-full h-full overflow-y-auto px-2 sm:px-24 xl:px-2 py-4 md:pt-8'>
      {isFetching && <Loader />}
      {data && <Outlet context={{ competition: data, competitionId: id }} />}
      {fetchError && <Four04 />}
      {error && <div>Error: {error}</div>}
    </div>
  );
};

export default Competition;
