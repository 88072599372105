import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';

const PasswordResetRequest = () => {
  const navigate = useNavigate();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      email: ''
    }
  });

  const formRef = useRef(null);

  const requestPwdReset = async (resetData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: resetData });

    return await axios
      .post(`/api/auth/password-reset/request`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitPasswordResetReq = async (formData) => {
    try {
      if (formData.email === '') {
        notifyError('Email cannot be empty');
        return;
      }

      const submitData = getTrimmedStringData(formData);

      const { data } = await requestPwdReset(submitData);

      if (data.success) {
        notifySuccess(data.message);

        navigate(`/`);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='h-main flex items-start justify-center px-4 sm:px-6 lg:px-8 py-6 md:py-12'>
      <div className='max-w-md w-full space-y-6'>
        <div>
          <h2 className='mt-6 text-center text-3xl font-bold text-white'>
            Password Reset Request
          </h2>
        </div>
        <form
          className='mt-8 space-y-4'
          ref={formRef}
          onSubmit={handleSubmit(submitPasswordResetReq)}
        >
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-300'
            >
              Email address
            </label>
            <input
              id='email'
              {...register('email')}
              name='email'
              type='email'
              autoComplete='email'
              required
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
              placeholder='Email address'
            />
          </div>

          <div className='pt-2'>
            <button
              type='submit'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <span className='flex items-center pl-3 uppercase'></span>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
