import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import RequireAuth from 'components/RequireAuth';
import Loader from 'components/Loader';
import Four04 from 'components/Four04';
import AthleteEditForm from './AthleteEditForm';

const AthleteEdit = () => {
  const { athleteId } = useOutletContext();
  const [fetchError, setFetchError] = useState(null);

  const getAthlete = async () => {
    try {
      const {
        data: { athlete }
      } = await axios.get(`/api/athletes/${athleteId}/admin`);

      return athlete;
    } catch (error) {
      console.log('error: ', error);
      setFetchError(error);
    }
  };
  const useAthlete = () => useQuery('athleteEdit', getAthlete);

  const { data, error, isFetching } = useAthlete();

  return (
    <RequireAuth>
      <div className='grow'>
        {isFetching && <Loader />}
        {data && <AthleteEditForm athlete={data} athleteId={athleteId} />}
        {fetchError && <Four04 />}
        {error && <div>Error: {error}</div>}
      </div>
    </RequireAuth>
  );
};

export default AthleteEdit;
