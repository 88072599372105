import { Link, useOutletContext } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import ConfirmDelete from 'components/ConfirmDelete';

import { notifyError, notifySuccess } from 'utils/toast';

import { PlusIcon } from '@heroicons/react/solid';

const CompOfficialsList = () => {
  const {
    competition: { officials },
    competitionId
  } = useOutletContext();
  const queryClient = useQueryClient();

  const deleteOfficial = async (officialId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return await axios
      .delete(
        `/api/competitions/${competitionId}/officials/${officialId}`,
        config
      )
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitDeleteOfficial = async (officialId) => {
    try {
      const { data } = await deleteOfficial(officialId);

      if (data.success) {
        notifySuccess('Official deleted');
        queryClient.invalidateQueries('competition');
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <>
      <h2 className='text-center text-lg text-red uppercase'>
        Competition Officials
      </h2>
      <ul className='mt-2 grid grid-cols-1 gap-3 sm:gap-6 lg:grid-cols-2 rounded'>
        {officials.length > 0 ? (
          officials.map(({ _id, firstName, lastName, email }) => (
            <li key={_id} className='col-span-1 h-16 flex shadow-sm rounded-md'>
              <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-md'>
                <div className='flex-1 px-4 py-2 text-sm'>
                  <div className='text-gray-900 font-medium text-base'>
                    {firstName} {lastName}
                  </div>
                  <div className='text-blue-800'>{email}</div>
                </div>
                <div className='justify-end flex mr-3'>
                  <div className='flex'>
                    <ConfirmDelete
                      submitDelete={() => submitDeleteOfficial(_id)}
                      title='Delete Official'
                    />
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li className='col-span-1 px-4 py-5 bg-white shadow-sm rounded-md'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
              No Officials Added
            </h3>
          </li>
        )}
      </ul>
      <Link
        to={`/competitions/${competitionId}/officials/add`}
        className='w-full md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Add Official'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600'
        >
          <PlusIcon className='h-5 w-5' />
          <span className='uppercase'>Add More</span>
        </button>
      </Link>
    </>
  );
};

export default CompOfficialsList;
