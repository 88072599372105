import { useRef } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import { notifyError, notifySuccess } from 'utils/toast';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const ObstacleForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    competition: { obstacles },
    competitionId
  } = useOutletContext();

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      number: obstacles.length + 1,
      bonus: '0'
    }
  });
  const formRef = useRef(null);

  const bonusOptions = () => {
    const current = obstacles.map((ob) => ob.bonusPoints.toString());

    const available = ['2', '3', '5'].filter(
      (bonus) => !current.includes(bonus)
    );
    available.unshift('0');
    return available;
  };

  const updateCompetition = async (competitionData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { competitionData } });

    return await axios
      .put(`/api/competitions/${competitionId}`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const generateObstacleData = (data) => ({
    number: Number.parseInt(data.number),
    name: data.name.trim(),
    bonusPoints: Number.parseInt(data.bonus)
  });

  const insertObstacle = (obstacle) => {
    const updatedObs = [...obstacles];
    // insert new obstacle
    updatedObs.splice(obstacle.number - 1, 0, obstacle);
    // renumber and return updated obstacles
    return updatedObs.map((ob, i) => ({
      ...ob,
      number: i + 1
    }));
  };

  const generateCompData = (obData) => ({
    obstacles: insertObstacle(obData)
  });

  const submitObstacle = async (submitData) => {
    try {
      const obData = generateObstacleData(submitData);
      const compData = generateCompData(obData);

      const { data } = await updateCompetition(compData);

      // if successful, refetch data
      if (data.success) {
        notifySuccess('Obstacle added');
        queryClient.invalidateQueries('competition');

        navigate(`/competitions/${competitionId}/obstacles`);
      } else {
        notifyError(data.message);
        reset();
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='relative lg:max-w-2xl lg:mx-auto rounded h-full pb-10'>
      <h2 className='text-center text-lg text-red uppercase'>
        Add New Obstacle
      </h2>
      <form
        className='min-h-fit'
        ref={formRef}
        onSubmit={handleSubmit(submitObstacle)}
      >
        <div className='shadow sm:rounded-md'>
          <div className='grid grid-cols-6 gap-y-4'>
            <div className='col-span-6'>
              <label
                htmlFor='number'
                className='block text-sm font-medium leading-5 text-gray-200'
              >
                Number
              </label>
              <input
                type='number'
                name='number'
                id='number'
                {...register('number')}
                className='mt-1  focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-6 sm:col-span-6'>
              <label
                htmlFor='name'
                className='block text-sm font-medium text-gray-200'
              >
                Obstacle Name
              </label>
              <input
                type='text'
                name='name'
                id='name'
                {...register('name')}
                className='mt-1  focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-6 sm:col-span-6'>
              <label
                htmlFor='bonus'
                className='block text-sm font-medium text-gray-200'
              >
                Bonus Points
              </label>
              <select
                {...register('bonus')}
                className='mt-1  focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              >
                {bonusOptions().map((bonus) => (
                  <option key={bonus} value={bonus}>
                    {bonus}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <button
          type='submit'
          className='w-full md:w-auto md:float-right  flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
          <div className='uppercase'>Add Obstacle</div>
        </button>
      </form>
      <Link
        to={`/competitions/${competitionId}/obstacles`}
        className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Cancel'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <XIcon className='h-5 w-5' aria-hidden='true' />
          <span className='uppercase'>Cancel</span>
        </button>
      </Link>
    </div>
  );
};

export default ObstacleForm;
