import { useDispatch, useSelector } from 'react-redux';

import { stopwatchActions } from 'store/stopwatch';

import { PauseIcon, PlayIcon } from '@heroicons/react/solid';

const { pauseStopwatch, resumeStopwatch } = stopwatchActions;

const StopwatchBtn = () => {
  const dispatch = useDispatch();
  const { isStopwatchRunning, isStopwatchPaused } = useSelector(
    (state) => state.stopwatch
  );

  const handlePause = () => {
    if (isStopwatchRunning) {
      dispatch(pauseStopwatch());
    }
  };

  const handleResume = () => {
    if (isStopwatchPaused) {
      dispatch(resumeStopwatch());
    }
  };

  return (
    <div className='inline-flex'>
      {isStopwatchPaused ? (
        <button
          className='inline-flex items-center p-0.5 border border-transparent text-green-800 bg-green-300 hover:bg-green-400 rounded-full'
          onClick={handleResume}
        >
          <PlayIcon className='h-8 w-8 shadow-sm shadow-green-500 rounded-full' />
        </button>
      ) : (
        <button
          className='inline-flex items-center p-0.5 border border-transparent text-red-800 bg-red-300 hover:bg-red-400 rounded-full'
          onClick={handlePause}
        >
          <PauseIcon className='h-8 w-8 shadow-sm shadow-red-500 rounded-full' />
        </button>
      )}
    </div>
  );
};

export default StopwatchBtn;
