import { useSelector } from 'react-redux';

const Scoreboard = () => {
  const { totalPoints, failCount, retryCount } = useSelector(
    (state) => state.tracker
  );

  return (
    <div className='max-w-4xl mx-auto mb-2'>
      <dl className='pt-1 rounded-lg bg-blue-900/20 border border-indigo-500 shadow-lg grid grid-cols-3'>
        <div className='flex flex-col text-center'>
          <dt className='order-2 text-sm leading-6 font-medium text-gray-400 uppercase'>
            Points
          </dt>
          <dd className='order-1 text-2xl text-white'>{totalPoints}</dd>
        </div>
        <div className='flex flex-col text-center'>
          <dt className='order-2 text-sm leading-6 font-medium text-gray-400 uppercase'>
            Retries
          </dt>
          <dd className='order-1 text-2xl text-white'>{retryCount}</dd>
        </div>
        <div className='flex flex-col text-center'>
          <dt className='order-2 text-sm leading-6 font-medium text-gray-400 uppercase'>
            Fails
          </dt>
          <dd className='order-1 text-2xl text-white'>{failCount}</dd>
        </div>
      </dl>
    </div>
  );
};

export default Scoreboard;
