import { notifyWarning } from './toast';

import { PWD_BLACKLIST } from './constants';

export const isBlacklistedPassword = (password) => {
  const blacklist = [...PWD_BLACKLIST];

  const results = blacklist.map((word) =>
    password.toLowerCase().includes(word)
  );

  return results.includes(true);
};

export const validatePassword = (password1, password2) => {
  if (password1.length < 8) {
    notifyWarning('Password must be at least 8 characters long');
    return false;
  }

  if (isBlacklistedPassword(password1)) {
    notifyWarning('Password is too common - please choose a different one');
    return false;
  }

  if (password1 !== password2) {
    notifyWarning('Passwords do not match');
    return false;
  }

  return true;
};
