import { SET_DATA_LOADING } from './types';

export const appInitialState = {
  dataLoading: false
};

const reducer = (state = appInitialState, action) => {
  switch (action.type) {
    case SET_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
