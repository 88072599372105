export const XIcon = ({ classes }) => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fal'
      data-icon='x'
      className={`fill-current ${classes}`}
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 384 512'
    >
      <path
        fill='currentColor'
        d='M380.2 453.7c5.703 6.75 4.859 16.84-1.891 22.56C375.3 478.7 371.7 480 368 480c-4.547 0-9.063-1.938-12.23-5.657L192 280.8l-163.8 193.6C25.05 478.1 20.53 480 15.98 480c-3.641 0-7.313-1.25-10.31-3.781c-6.75-5.719-7.594-15.81-1.891-22.56l167.2-197.7L3.781 58.32c-5.703-6.75-4.859-16.84 1.891-22.56c6.75-5.688 16.83-4.813 22.55 1.875L192 231.2l163.8-193.6c5.703-6.688 15.8-7.563 22.55-1.875c6.75 5.719 7.594 15.81 1.891 22.56l-167.2 197.7L380.2 453.7z'
      ></path>
    </svg>
  );
};
