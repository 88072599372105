import { Outlet } from 'react-router-dom';

const Competitions = () => {
  return (
    <main className='h-main flex flex-col max-w-4xl mx-auto '>
      <Outlet />
    </main>
  );
};

export default Competitions;
