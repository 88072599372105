import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/classNames';

const LeaderboardSelect = () => {
  let navigate = useNavigate();

  const getAvailableSeasons = async () => {
    const { data } = await axios.get('/api/leaderboards/seasons');

    return data;
  };

  const useAvailableSeasons = () =>
    useQuery('leaderboards', getAvailableSeasons);

  const { data } = useAvailableSeasons();

  const seasons = [
    {
      name: 'Current',
      href: '/leaderboards'
    }
  ];

  if (data) {
    seasons.push(
      ...data.seasons.map((season) => {
        const href =
          season.name === 'testing'
            ? '/leaderboards/testing'
            : `/leaderboards/${season.seasonYear}`;
        return {
          name: season.name,
          href
        };
      })
    );
  }

  const [selectedSeason, setSelectedSeason] = useState(seasons[0]);

  const handleSelect = (season) => {
    setSelectedSeason(season);
    navigate(season.href);
  };

  return (
    <Listbox value={selectedSeason} onChange={handleSelect}>
      {({ open }) => (
        <>
          <div className='relative w-[122px]'>
            <Listbox.Button className='relative w-full text-2xl bg-[#0f172a] border border-transparent rounded-md shadow-sm pl-3 pr-8 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'>
              <span className='block text-red text-center capitalize'>
                {selectedSeason.name}
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pt-1 pr-2 pointer-events-none'>
                <SelectorIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-20 mt-1 w-full text-gray-200 bg-gray-600 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                {seasons.map((season) => (
                  <Listbox.Option
                    key={season.name}
                    className={({ active }) =>
                      classNames(
                        active && 'bg-indigo-600',
                        'text-white cursor-default select-none relative py-2 pl-8 pr-4'
                      )
                    }
                    value={season}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate capitalize'
                          )}
                        >
                          {season.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default LeaderboardSelect;
