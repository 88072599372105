import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import { getTrimmedStringData } from 'utils/input';
import { notifyInfo } from 'utils/toast';

import { CheckIcon } from '@heroicons/react/outline';

const OfficialForm = ({ isAlreadyOfficial, submitOfficial }) => {
  const { handleSubmit, register, reset } = useForm();
  const formRef = useRef(null);

  const handleSubmitOfficial = async (formData) => {
    const exists = await isAlreadyOfficial(formData.email);
    if (exists) {
      notifyInfo('Official already exists');
      reset();
      return;
    }

    const submitData = getTrimmedStringData(formData);
    await submitOfficial(submitData);
  };

  return (
    <div className='relative h-full'>
      <h2 className='text-center text-lg text-red uppercase'>
        Add New Competition Official
      </h2>
      <form
        className='min-h-fit'
        ref={formRef}
        onSubmit={handleSubmit(handleSubmitOfficial)}
      >
        <div className='shadow sm:rounded-md'>
          <div className='grid grid-cols-6 gap-2 md:gap-4'>
            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium text-gray-200'
              >
                First name
              </label>
              <input
                type='text'
                name='firstName'
                id='firstName'
                {...register('firstName')}
                autoComplete='given-name'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='lastName'
                className='block text-sm font-medium text-gray-200'
              >
                Last name
              </label>
              <input
                type='text'
                name='lastName'
                id='lastName'
                {...register('lastName')}
                autoComplete='family-name'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-6'>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-200'
              >
                Email address
              </label>
              <input
                type='text'
                name='email'
                id='email'
                {...register('email')}
                autoComplete='email'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
          </div>
        </div>
        <button
          type='submit'
          className='w-full md:w-auto md:float-right  flex justify-center gap-1 mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
          <div className='uppercase'>Add New Official</div>
        </button>
      </form>
    </div>
  );
};

export default OfficialForm;
