import axios from 'axios';

// Sets auth JWT into default header for requests to server
export const setAuthTokenHeader = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Save auth JWT into local storage
export const saveAuthTokenToLS = (token) => {
  // console.log('saveAuthToken: ', token);

  localStorage.setItem('ixglToken', token);
};

// Get auth JWT from local storage
export const getAuthTokenFromLS = () => {
  const token = localStorage.getItem('ixglToken');
  // console.log('getAuthToken: ', token);
  return token;
};

// Remove auth JWT from local storage
export const removeAuthTokenFromLS = () => {
  // console.log('removeAuthToken');
  localStorage.removeItem('ixglToken');
};
