import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import AthleteSearch from './AthleteSearch';

import { notifyError, notifyInfo, notifySuccess } from 'utils/toast';

import { CheckIcon } from '@heroicons/react/solid';

const CompAthleteAdd = ({ competitionId, checkIfRegistered }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const [selectedAthlete, setSelectedAthlete] = useState('');

  const addAthlete = async (athlete) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { athlete } });

    return await axios
      .put(`/api/competitions/${competitionId}/athletes`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitCompAthlete = async () => {
    try {
      if (!isLoggedIn) {
        notifyInfo('Please login to register an athlete');
        navigate('/signin');
      } else if (!selectedAthlete) {
        notifyInfo('Please select an athlete');
      } else if (checkIfRegistered(selectedAthlete._id)) {
        notifyInfo('Athlete already registered');
        setSelectedAthlete('');
      }

      const athlete = {
        id: selectedAthlete.id
      };

      const { data } = await addAthlete(athlete);

      if (data.success) {
        notifySuccess('Athlete added to competition');
        queryClient.invalidateQueries('competition');

        setSelectedAthlete('');
      } else {
        notifyError(data.message);
        setSelectedAthlete('');
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='sm:px-4 mt-4'>
      <div className='w-full flex flex-col md:flex-row gap-3.5'>
        <AthleteSearch
          selectedAthlete={selectedAthlete}
          setSelectedAthlete={setSelectedAthlete}
          checkIfRegistered={checkIfRegistered}
        />
        <button
          type='button'
          className='md:self-end flex justify-center gap-1 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 '
          onClick={(e) => submitCompAthlete()}
          disabled={!selectedAthlete}
        >
          <CheckIcon className='h-5 w-5' />
          <div className='uppercase'>Register Athlete</div>
        </button>
      </div>
    </div>
  );
};

export default CompAthleteAdd;
