import { useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import { getMinutesAndSeconds, getMilliseconds } from 'utils/time';
import { notifyError, notifySuccess } from 'utils/toast';
import { classNames } from 'utils/classNames';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const ResultForm = ({ result, competitionId }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const defaultValues = useMemo(() => {
    const [minutes, seconds] = getMinutesAndSeconds(result.totalTime);

    return {
      minutes,
      seconds,
      points: result.points,
      failCount: result.failCount,
      retryCount: result.retryCount
    };
  }, [result]);

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const formRef = useRef(null);

  const editResultRequest = async (athleteResult) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { athleteResult } });

    return await axios
      .put(
        `/api/competitions/${competitionId}/results/${result.athlete._id}/edit`,
        body,
        config
      )
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const generateAthleteResult = (formData) => {
    let totalTime;

    if (formData.minutes || formData.seconds) {
      totalTime = getMilliseconds(formData.minutes, formData.seconds);
    } else {
      totalTime = result.totalTime;
    }

    const athleteResult = {
      totalTime,
      points: formData.points * 1,
      failCount: formData.failCount * 1,
      retryCount: formData.retryCount * 1
    };

    return athleteResult;
  };

  const submitEditResult = async (formData) => {
    try {
      const athleteResult = generateAthleteResult(formData);

      const res = await editResultRequest(athleteResult);

      if (res.data.success) {
        notifySuccess('Athlete result updated');
        queryClient.invalidateQueries('competition');

        navigate(`/competitions/${competitionId}/scores`);
      } else {
        notifyError(res.data.message);
      }
    } catch (error) {
      notifyError(error.message);
      reset();
    }
  };

  return (
    <div className='relative h-full'>
      <form
        className='min-h-fit'
        ref={formRef}
        onSubmit={handleSubmit(submitEditResult)}
      >
        <div className='shadow sm:rounded-md'>
          <div className='grid grid-cols-3 gap-2 md:gap-4'>
            <div className='col-span-1 sm:col-span-1'>
              <label
                htmlFor='totalTime'
                className='block text-sm font-medium text-gray-200'
              >
                Minutes
              </label>
              <input
                type='number'
                name='minutes'
                id='minutes'
                {...register('minutes')}
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-2 sm:col-span-1'>
              <label
                htmlFor='totalTime'
                className='block text-sm font-medium text-gray-200'
              >
                Seconds
              </label>
              <input
                type='number'
                step='0.001'
                name='seconds'
                id='seconds'
                {...register('seconds')}
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-1 sm:col-span-1'>
              <label
                htmlFor='points'
                className='block text-sm font-medium text-gray-200'
              >
                Points
              </label>
              <input
                type='number'
                name='points'
                id='points'
                {...register('points')}
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-1 sm:col-span-1'>
              <label
                htmlFor='failCount'
                className='block text-sm font-medium text-gray-200'
              >
                Fail Count
              </label>
              <input
                type='number'
                name='failCount'
                id='failCount'
                {...register('failCount')}
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
            <div className='col-span-1 sm:col-span-1'>
              <label
                htmlFor='retryCount'
                className='block text-sm font-medium text-gray-200'
              >
                Retry Count
              </label>
              <input
                type='number'
                name='retryCount'
                id='retryCount'
                {...register('retryCount')}
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>
          </div>
        </div>
        <button
          type='submit'
          disabled={!isDirty}
          className={classNames(
            'w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            isDirty && 'hover:bg-indigo-700'
          )}
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
          <div className='uppercase'>Update Result</div>
        </button>
      </form>
      <Link
        to={`/competitions/${competitionId}/scores`}
        className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Cancel'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <XIcon className='h-5 w-5' aria-hidden='true' />
          <span className='uppercase'>Cancel</span>
        </button>
      </Link>
    </div>
  );
};

export default ResultForm;
