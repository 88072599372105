import { useSelector } from 'react-redux';

import ObstacleBonus from './ObstacleBonus';

import { classNames } from 'utils/classNames';
import { formatStopwatchTime } from 'utils/time';

const Obstacle = ({ obstacle }) => {
  const {
    obstacleNumber,
    name,
    result,
    retries,
    bonusPoints,
    bonusCompleted,
    timestamp
  } = obstacle;

  const { currentObstacleNum, failCount, onObstacle } = useSelector(
    (state) => state.tracker
  );

  const showBonusButton = bonusPoints > 0;
  const isCurrentObstacle = obstacleNumber === currentObstacleNum && onObstacle;

  const numberColor =
    result === 'clear'
      ? 'bg-green-600'
      : result === 'fail'
      ? 'bg-red-600'
      : isCurrentObstacle
      ? 'bg-blue-600'
      : 'bg-gray-700';

  return (
    <div
      className={classNames(
        'max-w-4xl mx-auto mb-1 shadow-sm rounded-md border border-gray-200 bg-white',
        isCurrentObstacle && 'ring ring-inset ring-blue-600'
      )}
    >
      <div className='flex'>
        <div className='flex-shrink-0 flex items-center justify-center w-14 font-medium'>
          <span
            className={`flex items-center justify-center h-8 w-8 rounded-full text-white ${numberColor}`}
          >
            {obstacleNumber}
          </span>
        </div>

        <div className='flex-1 flex-col'>
          <div className='justify-self-center px-2 pt-1 text-base'>
            <span
              className={classNames(
                ' font-medium uppercase',
                isCurrentObstacle ? 'text-blue-600' : 'text-blue-800'
              )}
            >
              {name}
            </span>
          </div>
          <div className='flex mb-px'>
            <div className='ml-1 w-16'>
              <span
                className={classNames(
                  'px-2 py-0.5 rounded-md text-sm font-medium uppercase',
                  result === 'clear'
                    ? 'bg-green-200 text-green-800'
                    : result === 'fail'
                    ? 'bg-red-200 text-red-800'
                    : 'bg-gray-100 text-gray-800',
                  isCurrentObstacle && 'invisible'
                )}
              >
                {result}
              </span>
            </div>
            <div className='self-end py-0.5 text-xs text-gray-500 uppercase'>
              {timestamp !== 0 && (
                <span className='mr-2'>
                  At: <span>{formatStopwatchTime(timestamp)}</span>
                </span>
              )}
              {retries > 0 && <span>Retry: {retries}</span>}
            </div>
          </div>
        </div>

        <div className='self-center'></div>

        <div className='flex-shrink-0 self-center justify-end'>
          {showBonusButton && (
            <ObstacleBonus
              obstacleNumber={obstacleNumber}
              bonusPoints={bonusPoints}
              bonusCompleted={bonusCompleted}
              disabled={result !== 'clear' || failCount > 0}
              // disabled={result !== 'clear' || failCount > 0 || retries > 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Obstacle;
