import { combineReducers } from 'redux';

import app from './app';
import competition from './competition';
import countdown from './countdown';
import stopwatch from './stopwatch';
import tracker from './tracker';
import user from './user';

const reducers = {
  app,
  user,
  competition,
  countdown,
  stopwatch,
  tracker
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
