import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { notifyWarning } from 'utils/toast';

const AdminsOnly = ({ children }) => {
  let location = useLocation();
  const { loggedIn, user } = useSelector((state) => state.user);

  if (!loggedIn) {
    return <Navigate to={'/signin'} state={{ from: location }} replace />;
  }

  const isAdmin = user?.role === 'admin';

  if (!isAdmin) {
    notifyWarning('You are not authorized');
    return <Navigate to={`/`} replace />;
  }

  return children;
};

export default AdminsOnly;
