import {
  SET_COMPETITION_DATA,
  SET_STATUS,
  UPDATE_FINISHED_ATHLETES
} from './types';

export const competitionInitialState = {
  isLoaded: false,
  id: '',
  name: '',
  date: null,
  status: '',
  location: '',
  obstacles: [],
  athletes: [],
  officials: [],
  trackerObstacles: [],
  finishedAthletes: []
};

const reducer = (state = competitionInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COMPETITION_DATA:
      return {
        ...state,
        isLoaded: true,
        ...payload
      };

    case SET_STATUS:
      return {
        ...state,
        status: payload
      };

    case UPDATE_FINISHED_ATHLETES:
      return {
        ...state,
        finishedAthletes: [...state.finishedAthletes, payload]
      };

    default:
      return state;
  }
};

export default reducer;
