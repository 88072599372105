import { Tab } from '@headlessui/react';

const AthletesPanel = ({ athletes }) => {
  const isEmpty = athletes.length < 1;

  return (
    <Tab.Panel>
      <ul className='mt-3 grid grid-cols-1 gap-3 sm:gap-6 md:grid-cols-2 rounded'>
        {!isEmpty ? (
          athletes.map(({ id, firstName, lastName, ageGroup, division }) => (
            <li key={id} className='col-span-1 h-16 flex shadow-sm rounded-md'>
              <div className='w-full flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-md'>
                <div className='flex-1 px-4 py-2'>
                  <div className='text-gray-900 font-medium '>
                    {`${firstName} ${lastName}`}
                  </div>
                </div>
                <div className='flex-1 flex flex-col sm:flex-row sm:items-baseline sm:gap-x-4 md:flex-col md:justify-between gap-x-2 mr-3 py-2 '>
                  <div className='text-blue-800 text-sm'>{ageGroup}</div>
                  <div className='text-gray-500 text-xs uppercase'>
                    {division}
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li className='col-span-2 px-4 py-5 bg-white shadow-sm rounded-md'>
            <h3 className='text-lg leading-6 font-medium text-gray-900 text-center'>
              No Athletes Added
            </h3>
          </li>
        )}
      </ul>
    </Tab.Panel>
  );
};
export default AthletesPanel;
