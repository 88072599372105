import { Link, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CompetitionHeader from 'components/CompetitionHeader';
import Footer from 'components/Footer';

import { orderCompAthletesByScore } from 'utils/athletes';
import { formatStopwatchTime } from 'utils/time';

import { PencilAltIcon } from '@heroicons/react/solid';

const CompScores = () => {
  const { competition, competitionId } = useOutletContext();
  const { results, status } = competition;
  const { loggedIn, user } = useSelector((state) => state.user);

  const isAdmin = loggedIn && user?.role === 'admin';

  // order results by points, etc.
  const orderedResults =
    status === 'completed' ? results : orderCompAthletesByScore(results);

  return (
    <div className='relative h-full flex flex-col items-center'>
      <CompetitionHeader
        competition={competition}
        competitionId={competitionId}
      />

      <div className='py-1 flex align-baseline justify-center min-w-full sm:max-w-2xl lg:max-w-3xl sm:px-4'>
        <h2 className='text-center text-lg text-red uppercase'>
          Competition Results
        </h2>
        {isAdmin && status === 'completed' && (
          <Link
            to={`/competitions/${competitionId}/scores/edit`}
            className='translate-x-4 inline-flex items-center'
          >
            <button
              title='Edit Scores'
              className='inline-flex items-center rounded-full shadow-sm text-blue-600 sm:text-gray-300 bg-transparent transition-all hover:text-blue-600 hover:scale-125'
            >
              <PencilAltIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </Link>
        )}
      </div>

      {orderedResults && orderedResults.length > 0 ? (
        <div className='grow w-full px-4 sm:px-6 lg:px-8 '>
          <div className='-mx-4 mt-2 shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 rounded-md sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300 rounded-md overflow-hidden'>
              <thead className='bg-gray-50'>
                <tr className='text-left text-sm font-semibold text-gray-900 uppercase'>
                  <th scope='col' className='text-center py-3 px-0'>
                    Place
                  </th>
                  <th scope='col' className='px-3 py-3'>
                    Name
                  </th>
                  <th
                    scope='col'
                    className='text-center hidden px-3 py-3 sm:table-cell'
                  >
                    Points
                  </th>
                  <th scope='col' className='hidden px-3 py-3 md:table-cell'>
                    Time
                  </th>
                  <th
                    scope='col'
                    className='text-center hidden px-3 py-3 md:table-cell'
                  >
                    Fails
                  </th>
                  <th
                    scope='col'
                    className='text-center hidden px-3 py-3 md:table-cell'
                  >
                    Retries
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {orderedResults.map((result, idx) => {
                  if (!result.athlete) return null;

                  return (
                    <tr key={result.athlete._id}>
                      <td className='whitespace-nowrap py-1 md:py-2 px-0 text-sm font-medium text-gray-900'>
                        <span
                          className={
                            'mx-auto flex items-center justify-center h-6 w-6 rounded-full text-white bg-indigo-600'
                          }
                        >
                          {result.placing ? result.placing : idx + 1}
                        </span>
                      </td>
                      <td className='px-3 py-1 md:py-2 text-base text-gray-600 font-medium'>
                        <span className='text-blue-600'>
                          {`${result.athlete.firstName} ${result.athlete.lastName}`}
                        </span>
                        <div className='grid grid-cols-2'>
                          <dl className='md:hidden'>
                            <dt className='sr-only'>Points</dt>
                            <dd className='sm:hidden'>
                              <span className='font-medium text-xs uppercase'>
                                Points:{' '}
                              </span>
                              <span className='text-gray-800'>
                                {result.points}
                              </span>
                            </dd>
                            <dt className='sr-only'>Time</dt>
                            <dd>
                              <span className='text-xs uppercase'>Time: </span>
                              <span>
                                {formatStopwatchTime(result.totalTime)}
                              </span>
                            </dd>
                          </dl>
                          <dl className='md:hidden'>
                            <dt className='sr-only'>Fails</dt>
                            <dd>
                              <span className='text-xs uppercase'>Fails: </span>
                              <span>{result.failCount}</span>
                            </dd>
                            <dt className='sr-only'>Retries</dt>
                            <dd>
                              <span className='text-xs uppercase'>
                                Retries:{' '}
                              </span>
                              <span>{result.retryCount}</span>
                            </dd>
                          </dl>
                        </div>
                      </td>
                      <td className='text-center hidden px-3 py-1 md:py-2 font-medium text-base text-gray-800 sm:table-cell'>
                        {result.points}
                      </td>
                      <td className='hidden px-3 py-2 text-base text-gray-800 md:table-cell'>
                        {formatStopwatchTime(result.totalTime)}
                      </td>
                      <td className='text-center hidden px-3 py-2 text-base text-gray-800 md:table-cell'>
                        {result.failCount}
                      </td>
                      <td className='text-center hidden px-3 py-2 text-base text-gray-800 md:table-cell'>
                        {result.retryCount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className='grow w-full px-4 sm:px-6 lg:px-8'>
          <h3 className='p-4 bg-gray-300 text-lg leading-6 font-medium text-gray-900 text-center rounded-md'>
            No Results Yet
          </h3>
        </div>
      )}

      <div className='w-full -mx-4 my-4'>
        <Footer />
      </div>
    </div>
  );
};

export default CompScores;
