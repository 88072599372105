const compareAthleteResults = (a, b) => {
  if (a.points === b.points) {
    if (a.totalTime === b.totalTime) {
      if (a.failCount === b.failCount) {
        return a.retryCount - b.retryCount;
      }

      return a.failCount - b.failCount;
    }

    return a.totalTime - b.totalTime;
  }

  return b.points - a.points;
};

export const orderCompAthletesByScore = (results) =>
  results.sort(compareAthleteResults);

export const getPlaceOrdinal = (placeNum) => {
  if (typeof placeNum !== 'number' || placeNum < 1) return null;

  switch (placeNum % 10) {
    case 1:
      if (placeNum === 11) return `${placeNum}th`;
      return `${placeNum}st`;

    case 2:
      if (placeNum === 12) return `${placeNum}th`;
      return `${placeNum}nd`;

    case 3:
      if (placeNum === 13) return `${placeNum}th`;
      return `${placeNum}rd`;

    default:
      return `${placeNum}th`;
  }
};

export const getFailDeductionForUnattemptedObs = (
  currFailCount,
  unattemptedCount
) => {
  if (currFailCount >= 3) return 0;
  if (currFailCount === 2 && unattemptedCount > 0) return -2;
  if (currFailCount === 1 && unattemptedCount >= 2) return -3;
  if (currFailCount === 1 && unattemptedCount === 1) return -1;
  if (currFailCount === 0 && unattemptedCount >= 3) return -5;
  if (currFailCount === 0 && unattemptedCount === 2) return -3;
  if (currFailCount === 0 && unattemptedCount === 1) return -2;

  return 0;
};

// verify athlete data
export function verifyAthleteData(athlete) {
  if (!athlete.firstName) {
    return {
      error: 'Athlete first name is required',
      success: false
    };
  }

  if (!athlete.lastName) {
    return {
      error: 'Athlete last name is required',
      success: false
    };
  }

  if (!athlete.email) {
    return {
      error: 'Athlete email is required',
      success: false
    };
  }

  if (!athlete.division || athlete.division === 'select') {
    return {
      error: 'Athlete division is required',
      success: false
    };
  }

  if (!athlete.gender || athlete.gender === 'select') {
    return {
      error: 'Athlete gender is required',
      success: false
    };
  }

  if (!athlete.dob) {
    return {
      error: 'Athlete date of birth is required',
      success: false
    };
  }

  if (!athlete.city) {
    return {
      error: 'Athlete city is required',
      success: false
    };
  }

  if (athlete.country === 'select' || !athlete.country) {
    return {
      error: 'Athlete country is required',
      success: false
    };
  }

  return {
    error: null,
    success: true
  };
}
