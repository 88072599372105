import {
  START_STOPWATCH,
  PAUSE_STOPWATCH,
  RESUME_STOPWATCH,
  RESET_STOPWATCH,
  UPDATE_STOPWATCH_TIME
} from './types';

import { trackerOperations } from 'store/tracker';

const { updateTrackerTotalTime } = trackerOperations;

//action creators
const start = (startTime) => ({
  type: START_STOPWATCH,
  payload: startTime
});

const pause = (time) => ({
  type: PAUSE_STOPWATCH,
  payload: time
});

const resume = (startTime) => ({
  type: RESUME_STOPWATCH,
  payload: startTime
});

const updateTime = (time) => ({
  type: UPDATE_STOPWATCH_TIME,
  payload: time
});

const reset = () => ({ type: RESET_STOPWATCH });

//thunks
let stopwatchTimer;

export const startStopwatch = () => (dispatch, getState) => {
  const timerStart = Date.now();

  dispatch(start(timerStart));

  stopwatchTimer = setInterval(() => {
    const { stopwatch } = getState();
    const { startTime } = stopwatch;
    const time = Date.now() - startTime;

    dispatch(updateTime(time));
  }, 10);
};

export const pauseStopwatch = () => async (dispatch, getState) => {
  clearInterval(stopwatchTimer);

  const { stopwatch } = getState();
  const { startTime } = stopwatch;
  const time = Date.now() - startTime;

  await dispatch(pause(time));

  await dispatch(updateTrackerTotalTime());
};

export const resumeStopwatch = () => (dispatch, getState) => {
  const timerStart = Date.now();

  dispatch(resume(timerStart));

  stopwatchTimer = setInterval(() => {
    const { stopwatch } = getState();
    const { startTime } = stopwatch;
    const time = Date.now() - startTime;

    dispatch(updateTime(time));
  }, 10);
};

export const resetStopwatch = () => (dispatch) => {
  clearInterval(stopwatchTimer);

  dispatch(reset());
};
