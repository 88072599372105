import { Link, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { classNames } from 'utils/classNames';

import AthleteResendInvite from './AthleteResendInvite';

import { ArrowLeftIcon, PencilAltIcon } from '@heroicons/react/solid';

import { getPlaceOrdinal } from 'utils/athletes';
import { formatDateMDY } from 'utils/date';

export default function AthleteDisplay() {
  const { athlete, athleteId } = useOutletContext();

  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const userRole = useSelector((state) => state.user.user.role);
  const isAuthorized = userRole === 'admin' || userRole === 'organizer';

  const {
    userId,
    firstName,
    lastName,
    email,
    city,
    state,
    country,
    division,
    ageGroup,
    competitions
  } = athlete;

  return (
    <div className='grow relative max-w-7xl '>
      <Link
        to={'/athletes'}
        className='absolute top-0 left-2 inline-flex items-center'
      >
        <button
          type='button'
          className=' inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <ArrowLeftIcon className='h-5 w-5' aria-hidden='true' />
        </button>
      </Link>

      <h1 className='text-center text-2xl md:text-3xl text-red'>Athlete</h1>

      <div className='mt-4 bg-white shadow  rounded-lg'>
        <div className='px-4 py-5 sm:px-6'>
          <dl className='grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 md:grid-cols-3'>
            <div className='sm:col-span-1'>
              <dt className='text-sm font-medium text-gray-500'>Full name</dt>
              <dd className='mt-1 font-medium text-indigo-600'>
                {firstName} {lastName}
              </dd>
            </div>
            {isAuthorized && (
              <div className='sm:col-span-1 md:col-span-2'>
                <dt className='text-sm font-medium text-gray-500'>Email</dt>
                <dd className='mt-1 text-sm text-gray-900'>{email}</dd>
              </div>
            )}
            <div className='sm:col-span-1'>
              <dt className='text-sm font-medium text-gray-500'>Location</dt>
              <dd className='flex sm:flex-col mt-1 text-sm text-gray-900'>
                {isLoggedIn && (
                  <div>
                    {city}
                    {state ? ', ' : ' '}
                    {state}
                  </div>
                )}
                <div className={classNames(isLoggedIn && 'ml-4', 'sm:ml-0')}>
                  {country}
                </div>
              </dd>
            </div>
            {isLoggedIn && (
              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium text-gray-500'>Age Group</dt>
                <dd className='mt-1 text-sm text-gray-900'>{ageGroup}</dd>
              </div>
            )}
            <div className='sm:col-span-1'>
              <dt className='text-sm font-medium text-gray-500'>Division</dt>
              <dd className='mt-1 text-sm text-gray-900 uppercase'>
                {division ? division : 'N/A'}
              </dd>
            </div>
            <div className='sm:col-span-2 md:col-span-4'>
              <dt className='text-sm font-medium text-gray-500'>
                Competitions
              </dt>
              <dd className='mt-1 text-sm text-gray-900'>
                <ul className='rounded-md divide-y divide-gray-200'>
                  {competitions.length > 0 ? (
                    competitions.map((comp, idx) => {
                      if (!comp.competition) return null;

                      const {
                        competition: { name, city, date },
                        placing
                      } = comp;

                      return (
                        <li
                          key={`competition-${idx}`}
                          className='px-2 py-2 flex flex-col sm:flex-row gap-x-4 text-sm text-gray-900'
                        >
                          <div className='min-w-fit'>{formatDateMDY(date)}</div>
                          <div className='w-full md:flex'>
                            <div>
                              {name} - {city}
                            </div>
                            <div className='ml-auto'>
                              {placing && (
                                <span>{getPlaceOrdinal(placing)} Place</span>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li className='px-2 py-2 flex items-center justify-between text-sm text-gray-900'>
                      None
                    </li>
                  )}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
        {isAuthorized && (
          <div className='flex flex-col md:flex-row md:justify-between gap-y-4 px-4 pb-5 sm:px-6'>
            <Link
              to={`/athletes/${athleteId}/edit`}
              className='w-full md:w-auto h-min '
            >
              <button
                title='Edit Athlete'
                className='w-full md:w-auto flex items-center justify-center gap-1 px-4 py-2 border border-transparent rounded-md shadow-sm font-medium text-sm text-white bg-blue-600 hover:bg-blue-700'
              >
                <PencilAltIcon className='h-4 w-4' aria-hidden='true' />
                <span className='uppercase'>Edit Athlete</span>
              </button>
            </Link>
            <div>
              <AthleteResendInvite athleteUserId={userId} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
