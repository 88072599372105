//////////// UTILITIES ////////////

// Clone an array of obstacles
// @param arr: array of obstacles to clone
export const cloneObjArray = (arr) => arr.map((obj) => ({ ...obj }));

// Find the index of an obstacle in an array of obstacles
// @param obstacles: array of obstacles
// @param obstacleNum: obstacle number of obstacle to get
export const findIndexByObstacleNumber = (obstacles, obstacleNum) =>
  obstacles.findIndex((obstacle) => obstacle.obstacleNumber === obstacleNum);

// get total bonus points to award from obstacles
// @param obstacles: array of obstacles
export const getBonusPointsToAward = (obstacles) => {
  const bonusPoints = obstacles.reduce((acc, curr) => {
    if (curr.bonusCompleted) {
      return acc + curr.bonusPoints;
    }
    return acc;
  }, 0);

  return bonusPoints;
};
