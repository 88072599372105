import { Link } from 'react-router-dom';

import { formatDateMDYT } from 'utils/date';

import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { sortCompetitionsByStatus } from 'utils/competitions';
import { checkIsUserCompetitionAuthorized } from 'utils/officials';

const CompetitionItems = ({ competitions, loggedIn, user }) => {
  function isOfficial(competition) {
    if (!loggedIn) return false;

    const isAuthorized = checkIsUserCompetitionAuthorized(user, competition);

    return isAuthorized;
  }

  function getCompetitionLink(competition) {
    const { status, _id } = competition;

    if (status === 'completed') {
      return `/competitions/${_id}/scores`;
    }

    if (status === 'in progress' && !isOfficial(competition)) {
      return `/competitions/${_id}/scores`;
    }

    return `/competitions/${_id}`;
  }

  const sortedCompetitions = sortCompetitionsByStatus(competitions);

  return (
    <div className='shadow'>
      <ul className='divide-y divide-gray-200 space-y-4'>
        {sortedCompetitions.map((competition) => (
          <li
            key={competition._id}
            className='bg-white hover:bg-indigo-50 rounded-md'
          >
            <Link to={getCompetitionLink(competition)} className='block '>
              <div className='px-4 py-4 flex items-center sm:px-6'>
                <div className='min-w-0 flex-1 sm:flex sm:items-center sm:justify-between'>
                  <div>
                    <div className='flex flex-col sm:flex-row sm:items-baseline sm:gap-x-8 text-sm'>
                      <p className='min-w-[15rem] font-medium text-base text-indigo-600'>
                        {competition.name}
                      </p>
                      <p className='flex-shrink-0 font-normal text-gray-500'>
                        {competition.city},{' '}
                        {competition.state ? `${competition.state}, ` : ''}{' '}
                        {competition.country}
                      </p>
                    </div>
                    <div className='mt-2 flex'>
                      <div className='flex items-center text-sm text-gray-500'>
                        <CalendarIcon
                          className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                        <p>
                          <time dateTime={competition.date}>
                            {formatDateMDYT(competition.date)}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-1 flex-shrink-0 sm:mt-0'>
                    <div
                      className={`flex ${
                        competition.status === 'upcoming'
                          ? 'text-gray-500'
                          : competition.status === 'in progress'
                          ? 'text-green-600'
                          : 'text-blue-600'
                      } text-sm font-bold uppercase -space-x-1`}
                    >
                      {competition.status}
                    </div>
                  </div>
                </div>
                <div className='ml-5 flex-shrink-0'>
                  <ChevronRightIcon
                    className='h-7 w-7 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompetitionItems;
