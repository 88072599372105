import axios from 'axios';

import ConfirmAction from 'components/ConfirmAction';

import { notifyError, notifySuccess } from 'utils/toast';

import { ArrowRightIcon } from '@heroicons/react/outline';

const AthleteResendInvite = ({ athleteUserId }) => {
  const resendInviteEmail = async (athleteUserId) =>
    await axios
      .get(`/api/users/${athleteUserId}/invite`)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });

  const handleResendInvite = async (athleteUserId) => {
    try {
      const { data } = await resendInviteEmail(athleteUserId);

      if (data.success) {
        notifySuccess('Email invite resent');
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <ConfirmAction
      actionText='Resend Email Invite'
      btnIcon={<ArrowRightIcon className='h-4 w-4' />}
      btnLabel='Resend Email'
      btnClasses={
        'w-full md:w-auto px-4 py-2 font-medium text-sm text-white bg-indigo-600 rounded-md hover:bg-indigo-700'
      }
      onConfirm={() => handleResendInvite(athleteUserId)}
    />
  );
};

export default AthleteResendInvite;
