import { useRef, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import RequireAuth from 'components/RequireAuth';
import Footer from 'components/Footer';

import CountryOptions from '../CountryOptions';

import { verifyCompetitionData } from 'utils/competitions';
import { formatToUtc } from 'utils/date';
import { notifyError, notifySuccess, notifyWarning } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const CompetitionForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isProcessing, setIsProcessing] = useState(false);
  const { loggedIn, user } = useSelector((state) => state.user);

  const isAdminOrOrganizer =
    loggedIn && (user?.role === 'admin' || user?.role === 'organizer');

  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: '',
      date: '',
      hostGym: '',
      city: '',
      state: '',
      country: 'select'
    }
  });

  const formRef = useRef(null);

  const addCompetition = async (competitionData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { competitionData } });

    return await axios
      .post(`/api/competitions`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitCompetition = async (formData) => {
    try {
      if (isProcessing) {
        console.log('already processing');
        return;
      }

      const verified = verifyCompetitionData(formData);

      if (!verified.success) {
        notifyWarning(verified.error);
        return;
      }

      // set isProcessing
      setIsProcessing(true);

      const submitData = getTrimmedStringData(formData);

      const compDate = formatToUtc(submitData.date);

      const compData = {
        ...submitData,
        date: compDate
      };

      const { data } = await addCompetition(compData);
      setIsProcessing(false);

      if (data.success) {
        notifySuccess('Competition created');
        queryClient.invalidateQueries('competitions');

        navigate('/competitions');
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      notifyError(error.message);
      setIsProcessing(false);
    }
  };

  return isAdminOrOrganizer ? (
    <RequireAuth>
      <div className='flex flex-col relative lg:max-w-2xl lg:mx-auto rounded h-full overflow-y-auto px-2 sm:px-24 xl:px-2 pt-4 md:py-8'>
        <h1 className='text-center text-2xl md:text-3xl md:mb-8 text-red'>
          Create Competition
        </h1>

        <form
          className='min-h-fit mt-4'
          ref={formRef}
          onSubmit={handleSubmit(submitCompetition)}
        >
          <div className='shadow sm:rounded-md'>
            <div className='grid grid-cols-6 gap-3 md:gap-4'>
              <div className='col-span-6'>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-gray-200'
                >
                  Competition Name
                </label>
                <input
                  type='text'
                  name='name'
                  id='name'
                  {...register('name')}
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='date'
                  className='block text-sm font-medium text-gray-200'
                >
                  Competition Date
                </label>
                <input
                  type='datetime-local'
                  name='date'
                  id='date'
                  {...register('date')}
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='hostGym'
                  className='block text-sm font-medium text-gray-200'
                >
                  Host Gym
                </label>
                <input
                  type='text'
                  name='hostGym'
                  id='hostGym'
                  {...register('hostGym')}
                  autoComplete='address-level2'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6'>
                <label
                  htmlFor='city'
                  className='block text-sm font-medium text-gray-200'
                >
                  City
                </label>
                <input
                  type='text'
                  name='city'
                  id='city'
                  {...register('city')}
                  autoComplete='address-level2'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='region'
                  className='block text-sm font-medium text-gray-200'
                >
                  State / Province
                </label>
                <input
                  type='text'
                  name='state'
                  id='state'
                  {...register('state')}
                  autoComplete='address-level1'
                  className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>

              <div className='col-span-6 md:col-span-3'>
                <label
                  htmlFor='country'
                  className='block text-sm font-medium text-gray-200'
                >
                  Country
                </label>
                <select
                  name='country'
                  id='country'
                  {...register('country')}
                  autoComplete='country-name'
                  className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                  defaultValue={'Select a country'}
                >
                  <CountryOptions />
                </select>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <CheckIcon className='h-5 w-5' aria-hidden='true' />
            <div className='uppercase'>Create Competition</div>
          </button>
        </form>
        <Link
          to={`/competitions`}
          className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
        >
          <button
            type='button'
            title='Cancel'
            className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <XIcon className='h-5 w-5' aria-hidden='true' />
            <span className='uppercase'>Cancel</span>
          </button>
        </Link>
        <div className='mt-auto mb-2'>
          <Footer />
        </div>
      </div>
    </RequireAuth>
  ) : (
    <Navigate to={`/`} replace />
  );
};

export default CompetitionForm;
