import compareDesc from 'date-fns/compareDesc';
import compareAsc from 'date-fns/compareAsc';
import parseISO from 'date-fns/parseISO';

function getStatusCompareValue(status) {
  switch (status) {
    case 'in progress':
      return 1;
    case 'upcoming':
      return 2;
    case 'completed':
      return 3;
    default:
      return 0;
  }
}

export function compareStatus(a, b) {
  const statusA = getStatusCompareValue(a.status);
  const statusB = getStatusCompareValue(b.status);

  let comparison = 0;

  if (statusA > statusB) {
    comparison = 1;
  } else if (statusA < statusB) {
    comparison = -1;
  } else if (statusA === statusB) {
    const dateA = parseISO(a.date);
    const dateB = parseISO(b.date);

    comparison =
      a.status === 'completed'
        ? compareDesc(dateA, dateB)
        : compareAsc(dateA, dateB);
  }

  return comparison;
}

export function sortCompetitionsByStatus(competitions) {
  if (competitions.length < 2) return competitions;

  let sorted = JSON.parse(JSON.stringify(competitions));

  sorted.sort(compareStatus);

  return sorted;
}

// verify competition data
export function verifyCompetitionData(competition) {
  if (!competition.name) {
    return {
      error: 'Competition name is required',
      success: false
    };
  }

  if (!competition.date) {
    return {
      error: 'Competition date is required',
      success: false
    };
  }

  if (!competition.city) {
    return {
      error: 'Competition city is required',
      success: false
    };
  }

  if (competition.country === 'select' || !competition.country) {
    return {
      error: 'Competition country is required',
      success: false
    };
  }

  return {
    error: null,
    success: true
  };
}
