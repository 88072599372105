import { useRef } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import { notifyError, notifySuccess } from 'utils/toast';
import { classNames } from 'utils/classNames';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const ObstacleEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { competition, competitionId } = useOutletContext();

  const {
    state: { obstacleNumber }
  } = location;
  const obData = competition.obstacles.find(
    (obstacle) => obstacle.number === obstacleNumber
  );

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    register,
    reset
  } = useForm({
    defaultValues: {
      number: obData.number,
      name: obData.name.trim(),
      bonus: obData.bonusPoints
    }
  });

  const formRef = useRef(null);

  const bonusOptions = () => {
    const current = competition.obstacles.map((ob) =>
      ob.bonusPoints.toString()
    );
    const available = ['2', '3', '5'].filter(
      (bonus) =>
        !current.includes(bonus) || bonus === obData.bonusPoints.toString()
    );
    available.unshift('0');
    return available;
  };

  const updateObstaclesRequest = async (url, data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data });

    return await axios.put(url, body, config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.message) {
        return error;
      } else {
        return { message: 'Something went wrong' };
      }
    });
  };

  const generateObstacleData = (data) => ({
    number: Number.parseInt(data.number),
    name: data.name.trim(),
    bonusPoints: Number.parseInt(data.bonus),
    _id: obData._id
  });

  const renumberObstacles = (obstaclesArray) => {
    const renumbered = obstaclesArray.map((obstacle, index) => {
      return {
        ...obstacle,
        number: index + 1
      };
    });
    return renumbered;
  };

  const updateAndRenumberObstacles = (obstacle, currentObs) => {
    const filtered = currentObs.filter((ob) => ob._id !== obstacle._id);

    filtered.splice(obstacle.number - 1, 0, obstacle);

    const renumbered = renumberObstacles(filtered);
    return renumbered;
  };

  const generateObstaclesData = (data) => {
    const obstacle = generateObstacleData(data);

    const obstacles = updateAndRenumberObstacles(
      obstacle,
      competition.obstacles
    );

    return obstacles;
  };

  const submitEditObstacle = async (submitData) => {
    try {
      let result;

      if (dirtyFields.number) {
        const obstacles = generateObstaclesData(submitData);

        result = await updateObstaclesRequest(
          `/api/competitions/${competitionId}/obstacles`,
          { obstaclesData: obstacles }
        );
      } else {
        const obstacle = generateObstacleData(submitData);

        result = await updateObstaclesRequest(
          `/api/competitions/${competitionId}/obstacles/${obData._id}`,
          { obstacleData: obstacle }
        );
      }

      // if successful, refetch data
      if (result.data.success) {
        notifySuccess('Obstacle updated');
        queryClient.invalidateQueries('competition');

        navigate(`/competitions/${competitionId}/obstacles`);
      } else {
        notifyError(result.data.message);
        reset();
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='relative lg:max-w-2xl lg:mx-auto rounded h-full pb-10'>
      <h2 className='text-center text-lg text-red uppercase'>Edit Obstacle</h2>
      <form
        className='min-h-fit'
        ref={formRef}
        onSubmit={handleSubmit(submitEditObstacle)}
      >
        <div className='shadow sm:rounded-md'>
          <div className='grid grid-cols-6 gap-y-4'>
            <div className='col-span-6'>
              <label
                htmlFor='number'
                className='block text-sm font-medium leading-5 text-gray-200'
              >
                Number
              </label>
              <input
                type='number'
                name='number'
                id='number'
                {...register('number')}
                defaultValue={obData.number}
                className='mt-1  focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
              <div className='col-span-6 sm:col-span-6'>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-gray-200'
                >
                  Obstacle Name
                </label>
                <input
                  type='text'
                  name='name'
                  id='name'
                  {...register('name')}
                  defaultValue={obData.name}
                  className='mt-1  focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>
              <div className='col-span-6 sm:col-span-6'>
                <label
                  htmlFor='bonus'
                  className='block text-sm font-medium text-gray-200'
                >
                  Bonus Points
                </label>
                <select
                  name='bonus'
                  id='bonus'
                  {...register('bonus')}
                  className='mt-1  focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                >
                  {bonusOptions().map((bonus) => (
                    <option key={bonus} value={bonus}>
                      {bonus}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <button
          type='submit'
          className={classNames(
            'w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            isDirty && 'hover:bg-indigo-700'
          )}
          disabled={!isDirty}
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
          <div className='uppercase'>Update Obstacle</div>
        </button>
      </form>
      <Link
        to={`/competitions/${competitionId}/obstacles`}
        className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Cancel'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <XIcon className='h-5 w-5' aria-hidden='true' />
          <span className='uppercase'>Cancel</span>
        </button>
      </Link>
    </div>
  );
};

export default ObstacleEdit;
