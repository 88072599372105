import { Fragment, useState } from 'react';

import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';

import { XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, FilterIcon } from '@heroicons/react/solid';

import { classNames } from '../../utils/classNames';

export default function LeaderboardFilters({
  ageGroupFilters,
  divisionFilters,
  handleAgeGroupChange,
  handleDivisionFilterChange,
  handleClearAllFilters
}) {
  const [open, setOpen] = useState(false);
  const activeAgeGroups = ageGroupFilters.reduce(
    (acc, ageGroup) => (ageGroup.checked ? ++acc : acc),
    0
  );
  const activeDivisions = divisionFilters.reduce(
    (acc, division) => (division.checked ? ++acc : acc),
    0
  );

  const handleGroupChange = (e) =>
    handleAgeGroupChange(e.target.value, e.target.checked);

  const handleDivisionChange = (e) =>
    handleDivisionFilterChange(e.target.value, e.target.checked);

  return (
    <div className='mx-auto max-w-fit bg-indigo-500 text-white rounded-md'>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-40 sm:hidden'
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='ml-auto relative max-w-xs w-full h-full bg-gray-800 shadow-xl py-4 pb-6 flex flex-col overflow-y-auto'>
              <div className='px-4 flex items-center justify-between'>
                <h2 className='text-lg font-medium text-white'>Filters</h2>
                <button
                  type='button'
                  className='-mr-2 w-10 h-10  p-2 rounded-md flex items-center justify-center text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>

              {/* Filters */}
              <form className='mt-4'>
                <Disclosure
                  as='div'
                  key={'ageGroup'}
                  defaultOpen={true}
                  className='border-t border-gray-200 px-4 py-6'
                >
                  {({ open }) => (
                    <>
                      <h3 className='-mx-2 -my-3 flow-root'>
                        <Disclosure.Button className='px-2 py-3  w-full flex items-center justify-between text-sm text-white'>
                          <span className='font-medium'>Age Group</span>
                          <span className='ml-6 flex items-center'>
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-5 w-5 transform'
                              )}
                              aria-hidden='true'
                            />
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className='pt-4'>
                        <div className='space-y-4'>
                          {ageGroupFilters.map((group, groupIdx) => (
                            <div
                              key={group.value}
                              className='flex items-center'
                            >
                              <input
                                id={`filter-mobile-ageGroup-${groupIdx}`}
                                name={`ageGroup-${group.value}`}
                                defaultValue={group.value}
                                type='checkbox'
                                onChange={(e) => handleGroupChange(e)}
                                checked={group.checked}
                                className='h-4 w-4 border-gray-300 rounded text-indigo-600'
                              />
                              <label
                                htmlFor={`filter-mobile-ageGroup-${groupIdx}`}
                                className='ml-3 text-sm text-gray-300'
                              >
                                {group.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure
                  as='div'
                  key={'division'}
                  defaultOpen={true}
                  className='border-t border-gray-200 px-4 py-6'
                >
                  {({ open }) => (
                    <>
                      <h3 className='-mx-2 -my-3 flow-root'>
                        <Disclosure.Button className='px-2 py-3  w-full flex items-center justify-between text-sm text-white'>
                          <span className='font-medium'>Division</span>
                          <span className='ml-6 flex items-center'>
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-5 w-5 transform'
                              )}
                              aria-hidden='true'
                            />
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className='pt-4'>
                        <div className='space-y-4'>
                          {divisionFilters.map((division, divisionIdx) => (
                            <div
                              key={division.value}
                              className='flex items-center'
                            >
                              <input
                                id={`filter-mobile-division-${divisionIdx}`}
                                name={`division-${division.value}`}
                                defaultValue={division.value}
                                type='checkbox'
                                onChange={(e) => handleDivisionChange(e)}
                                checked={division.checked}
                                className='h-4 w-4 border-gray-300 rounded text-indigo-600'
                              />
                              <label
                                htmlFor={`filter-mobile-division-${divisionIdx}`}
                                className='ml-3 text-sm text-gray-300 capitalize'
                              >
                                {division.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className='mx-auto px-4 text-center sm:px-4 lg:px-6'>
        <section aria-labelledby='filter-heading' className=''>
          <h2 id='filter-heading' className='sr-only'>
            Leaderboard filters
          </h2>

          <div className='flex items-center justify-between'>
            <button
              type='button'
              className='inline-flex items-center py-1 text-sm font-medium text-white hover:text-gray-900 sm:hidden'
              onClick={() => setOpen(true)}
            >
              <FilterIcon className='h-4 w-4 mr-1' aria-hidden='true' />
              Filters
              {activeAgeGroups + activeDivisions > 0 ? (
                <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                  {activeAgeGroups + activeDivisions}
                </span>
              ) : null}
            </button>

            <FilterIcon
              className='h-4 w-4 my-1 mr-2 hidden sm:block'
              aria-hidden='true'
            />

            <Popover.Group className='hidden sm:flex sm:items-baseline sm:space-x-6'>
              <Popover
                as='div'
                key={'ageGroups'}
                id='desktop-menu'
                className='relative z-10 inline-block text-left'
              >
                <Popover.Button className='group inline-flex items-center justify-center py-0.5 text-sm font-medium text-white'>
                  <span>Age Groups</span>
                  {activeAgeGroups > 0 ? (
                    <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                      {activeAgeGroups}
                    </span>
                  ) : null}
                  <ChevronDownIcon
                    className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-white'
                    aria-hidden='true'
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-gray-800 rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <form className='space-y-4'>
                      {ageGroupFilters.map((group, groupIdx) => (
                        <div key={group.value} className='flex items-center'>
                          <input
                            id={`filter-ageGroup-${groupIdx}`}
                            name={`ageGroup-${group.value}`}
                            defaultValue={group.value}
                            type='checkbox'
                            onChange={(e) => handleGroupChange(e)}
                            checked={group.checked}
                            className='h-4 w-4 border-gray-300 rounded text-indigo-600'
                          />
                          <label
                            htmlFor={`filter-ageGroup-${groupIdx}`}
                            className='ml-3 pr-6 text-sm font-medium text-white whitespace-nowrap'
                          >
                            {group.value}
                          </label>
                        </div>
                      ))}
                    </form>
                  </Popover.Panel>
                </Transition>
              </Popover>
              <Popover
                as='div'
                key={'divisions'}
                id='desktop-menu'
                className='relative z-10 inline-block text-left'
              >
                <div>
                  <Popover.Button className='group inline-flex items-center justify-center text-sm font-medium text-white'>
                    <span>Divisions</span>
                    {activeDivisions > 0 ? (
                      <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums'>
                        {activeDivisions}
                      </span>
                    ) : null}
                    <ChevronDownIcon
                      className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-white'
                      aria-hidden='true'
                    />
                  </Popover.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-gray-800 rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <form className='space-y-4'>
                      {divisionFilters.map((division, divisionIdx) => (
                        <div key={division.value} className='flex items-center'>
                          <input
                            id={`filter-division-${divisionIdx}`}
                            name={`division-${division.value}`}
                            defaultValue={division.value}
                            type='checkbox'
                            onChange={(e) => handleDivisionChange(e)}
                            checked={division.checked}
                            className='h-4 w-4 border-gray-300 rounded text-indigo-600'
                          />
                          <label
                            htmlFor={`filter-division-${divisionIdx}`}
                            className='ml-3 pr-6 text-sm font-medium text-white capitalize whitespace-nowrap'
                          >
                            {division.value}
                          </label>
                        </div>
                      ))}
                    </form>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </Popover.Group>

            <button
              type='button'
              className='inline-flex items-center ml-3 pl-3 border-l border-gray-400 text-sm font-medium text-gray-300 hover:text-white'
              onClick={() => handleClearAllFilters()}
            >
              <span>Clear All</span>
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
