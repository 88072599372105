import { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { Combobox } from '@headlessui/react';

import { CheckIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/classNames';

const AthleteSearch = ({
  selectedAthlete,
  setSelectedAthlete,
  checkIfRegistered
}) => {
  const [query, setQuery] = useState('');
  // console.log('query: ', query);

  const showIcon = query === '' && !selectedAthlete;

  const getAthletes = async () => {
    const { data } = await axios.get('/api/athletes/basic');

    return data;
  };
  const useAthletes = () => useQuery('athletesSearch', getAthletes);

  const { data, error } = useAthletes();

  let filteredAthletes = [];

  if (data) {
    const available = data.athletes.filter(
      (athlete) => !checkIfRegistered(athlete.id)
    );
    // console.log('available: ', available);
    const filtered =
      query === '' && data
        ? available
        : available.filter((athlete) => {
            return athlete.fullName.toLowerCase().includes(query.toLowerCase());
          });
    filteredAthletes = filtered.slice(0, 4);
  }

  return (
    <>
      {error && <div>Error: {error}</div>}
      {data && (
        <Combobox
          as='div'
          className='flex-1'
          value={selectedAthlete}
          onChange={setSelectedAthlete}
        >
          <Combobox.Label className='block text-sm font-medium text-red'>
            Search Athletes
          </Combobox.Label>
          <div className='relative'>
            {showIcon && (
              <SearchIcon
                className='pointer-events-none absolute top-3 left-4 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            )}
            <Combobox.Input
              className='w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(athlete) => {
                // console.log('athlete: ', athlete);
                return athlete.fullName ? athlete.fullName : '';
              }}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
              <SelectorIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </Combobox.Button>

            {filteredAthletes.length > 0 && (
              <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {filteredAthletes.map((athlete) => (
                  <Combobox.Option
                    key={athlete.id}
                    value={athlete}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {athlete.fullName}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      )}
    </>
  );
};

export default AthleteSearch;
