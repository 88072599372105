import { appInitialState } from './app/reducer';
import { competitionInitialState } from './competition/reducer';
import { countdownInitialState } from './countdown/reducer';
import { stopwatchInitialState } from './stopwatch/reducer';
import { trackerInitialState } from './tracker/reducer';
import { userInitialState } from './user/reducer';

const initialState = {
  app: appInitialState,
  user: userInitialState,
  competition: competitionInitialState,
  countdown: countdownInitialState,
  stopwatch: stopwatchInitialState,
  tracker: trackerInitialState
};

export default initialState;
