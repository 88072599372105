import Footer from 'components/Footer';

const Support = () => {
  return (
    <main className='flex flex-col items-center h-main w-full flex-1 pt-10 md:px-20 mb-10 text-center'>
      <h1 className='text-3xl md:text-4xl font-bold mb-8 text-red'>
        IXGL Support Email
      </h1>
      <div className='p-4 rounded-md bg-gray-600'>
        <a
          className='text-lg text-white'
          target='_blank'
          href='mailto:ixgl@gfam.live'
          rel='noreferrer'
        >
          ixgl@gfam.live
        </a>
      </div>
      <div className='w-full mt-auto'>
        <Footer />
      </div>
    </main>
  );
};

export default Support;
