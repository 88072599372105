// trim whitespace from beginning and end of strings
// in data object
export const getTrimmedStringData = (data) => {
  for (const field in data) {
    if (data[field] !== undefined && typeof data[field] === 'string') {
      data[field] = data[field].trim();
    }
  }

  return data;
};
