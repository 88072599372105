import { useRef } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import CountryOptions from '../CountryOptions';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';

import { CheckIcon, XIcon } from '@heroicons/react/outline';

const CompAthleteForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { competitionId } = useOutletContext();
  // console.log('competitionId:', competitionId);

  const {
    handleSubmit,
    // formState: { errors },
    register
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      division: 'select',
      gender: 'select',
      dob: '',
      city: '',
      state: '',
      country: 'select'
    }
  });

  const formRef = useRef(null);

  const addAthlete = async (athlete) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ data: { athlete } });

    return await axios
      .put(`/api/competitions/${competitionId}/athletes`, body, config)
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.message) {
          return error;
        } else {
          return { message: 'Something went wrong' };
        }
      });
  };

  const submitAthlete = async (formData) => {
    try {
      const submitData = getTrimmedStringData(formData);
      const athlete = {
        data: submitData
      };

      const { data } = await addAthlete(athlete);

      if (data.success) {
        notifySuccess('Athlete created and added to competition');
        queryClient.invalidateQueries('competition');

        navigate(`/competitions/${competitionId}/athletes`);
      } else {
        notifyError(data.message);
        console.log('add athlete error:', data.message);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className='relative lg:max-w-2xl lg:mx-auto rounded h-full pb-10'>
      <h1 className='text-center text-2xl md:text-3xl mb-4 md:mb-8 text-red'>
        Create Competition Athlete
      </h1>

      <form ref={formRef} onSubmit={handleSubmit(submitAthlete)}>
        <div className='shadow sm:rounded-md'>
          <div className='grid grid-cols-6 gap-3 md:gap-4'>
            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium text-gray-200'
              >
                First name
              </label>
              <input
                type='text'
                name='firstName'
                id='firstName'
                {...register('firstName')}
                autoComplete='given-name'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='lastName'
                className='block text-sm font-medium text-gray-200'
              >
                Last name
              </label>
              <input
                type='text'
                name='lastName'
                id='lastName'
                {...register('lastName')}
                autoComplete='family-name'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-200'
              >
                Email address
              </label>
              <input
                type='text'
                name='email'
                id='email'
                {...register('email')}
                autoComplete='email'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='gender'
                className='block text-sm font-medium text-gray-200'
              >
                Division
              </label>
              <select
                id='division'
                {...register('division')}
                name='division'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
              >
                <option value='select'>Select Division</option>
                <option value='intro'>Intro</option>
                <option value='intermediate'>Intermediate</option>
                <option value='advanced'>Advanced</option>
                <option value='elite'>Elite</option>
              </select>
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='gender'
                className='block text-sm font-medium text-gray-200'
              >
                Gender
              </label>
              <select
                id='gender'
                {...register('gender')}
                name='gender'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
              >
                <option value='select'>Select Gender</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </select>
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='dob'
                className='block text-sm font-medium text-gray-200'
              >
                Date of Birth
              </label>
              <input
                type='date'
                name='dob'
                id='dob'
                {...register('dob')}
                max='2009-01-01'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6'>
              <label
                htmlFor='city'
                className='block text-sm font-medium text-gray-200'
              >
                City
              </label>
              <input
                type='text'
                name='city'
                id='city'
                {...register('city')}
                autoComplete='address-level2'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6 md:col-span-3'>
              <label
                htmlFor='region'
                className='block text-sm font-medium text-gray-200'
              >
                State / Province
              </label>
              <input
                type='text'
                name='state'
                id='state'
                {...register('state')}
                autoComplete='address-level1'
                className='mt-1 focus:ring-red focus:border-red block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
              />
            </div>

            <div className='col-span-6 md:col-span-3'>
              <label
                htmlFor='country'
                className='block text-sm font-medium text-gray-200'
              >
                Country
              </label>
              <select
                name='country'
                id='country'
                {...register('country')}
                autoComplete='country-name'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                defaultValue={'Select a country'}
              >
                <CountryOptions />
              </select>
            </div>
          </div>
        </div>
        <button
          type='submit'
          className='w-full md:w-auto md:float-right flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
          <div className='uppercase'>Create Athlete</div>
        </button>
      </form>
      <Link
        to={`/competitions/${competitionId}/athletes`}
        className='w-full md:mr-4 md:w-auto md:float-right inline-flex items-center'
      >
        <button
          type='button'
          title='Cancel'
          className='w-full flex justify-center gap-1 mt-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <XIcon className='h-5 w-5' aria-hidden='true' />
          <span className='uppercase'>Cancel</span>
        </button>
      </Link>
    </div>
  );
};

export default CompAthleteForm;
