export const checkIsUserCompetitionOfficial = (user, officials) => {
  if (!user || !officials) {
    return false;
  }

  const found = officials.find((official) => {
    // console.log('official', official);
    // console.log('user', user);
    return official.email.toLowerCase() === user.email.toLowerCase();
  });
  // console.log('found: ', found);

  return found ? true : false;
};

export const checkIsUserCompetitionAuthorized = (user, competition) => {
  if (!user) return false;

  if (user.role === 'admin') {
    // console.log('user is admin');
    return true;
  }

  if (competition.createdBy === user._id) {
    // console.log('user is competition creator');
    return true;
  }

  const found = competition.officials.find((official) => {
    return official._id === user._id;
  });

  // console.log('official found: ', !!found);

  return !!found;
};
