export const sortOptions = [
  {
    title: 'Placing',
    current: true
  },
  {
    title: 'Name',
    current: false
  }
];

export function compareNames(a, b) {
  const nameA = a.lastName.toLowerCase();
  const nameB = b.lastName.toLowerCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export function compareDivisions(a, b) {
  const nameA = a.division.toLowerCase();
  const nameB = b.division.toLowerCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

const comparePlacing = (a, b) => a.placing - b.placing;

export function sortLeaderboard(leaderboard, selected) {
  if (leaderboard.length < 2) return leaderboard;

  let sorted = JSON.parse(JSON.stringify(leaderboard));

  if (selected.title === 'Name') {
    sorted.sort(compareNames);
  } else if (selected.title === 'Placing') {
    sorted.sort(comparePlacing);
  }

  // console.log('sorted: ', sorted);
  return sorted;
}

export const ageGroupsFilters = [
  { value: 'Youth', checked: false },
  { value: 'Women 17-24', checked: false },
  { value: 'Women 25-35', checked: false },
  { value: 'Women 36+', checked: false },
  { value: 'Men 17-27', checked: false },
  { value: 'Men 28-38', checked: false },
  { value: 'Men 39+', checked: false }
];

export const divisionsFilters = [
  { value: 'intro', checked: false },
  { value: 'intermediate', checked: false },
  { value: 'advanced', checked: false },
  { value: 'elite', checked: false }
];

export function filterLeaderboard(leaderboard, filters) {
  let filteredBoard = JSON.parse(JSON.stringify(leaderboard));
  const ageGroupFilters = filters.ageGroup.reduce((acc, curr) => {
    if (curr.checked) acc.push(curr.value);

    return acc;
  }, []);

  const divisionFilters = filters.division.reduce((acc, curr) => {
    if (curr.checked) acc.push(curr.value);

    return acc;
  }, []);

  if (ageGroupFilters.length > 0) {
    filteredBoard = filteredBoard.filter((athlete) =>
      ageGroupFilters.includes(athlete.ageGroup)
    );
  }

  if (divisionFilters.length > 0) {
    filteredBoard = filteredBoard.filter((athlete) =>
      divisionFilters.includes(athlete.division)
    );
  }

  // console.log('filteredBoard: ', filteredBoard);
  return filteredBoard;
}
