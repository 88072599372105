import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AthleteSelect from './AthleteSelect';
import ObstacleControls from './ObstacleControls';
import Obstacles from './Obstacles';
import Scoreboard from './Scoreboard';
import StatusArea from './StatusArea';
import NextObstacle from './NextObstacle';
import TimeDisplay from './TimeDisplay';
import ConfirmAction from 'components/ConfirmAction';
import Four04 from 'components/Four04';

import { competitionActions } from 'store/competition';
import { countdownActions } from 'store/countdown';
import { stopwatchActions } from 'store/stopwatch';
import { trackerOperations } from 'store/tracker';

import { notifySuccess } from 'utils/toast';

import { CheckIcon } from '@heroicons/react/solid';

const { loadCompetitionData, finalizeCompetition } = competitionActions;
const { resetRestCountdown } = countdownActions;
const { resetStopwatch } = stopwatchActions;
const { initializeTracker, resetAthlete } = trackerOperations;

const CompetitionTracker = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const competition = location.state?.competition;

  const compDataLoaded = useSelector((state) => state.competition.isLoaded);
  const finishedAthletes = useSelector(
    (state) => state.competition.finishedAthletes
  );
  const isFinished = useSelector((state) => state.tracker.isFinished);

  const [selectedAthlete, setSelectedAthlete] = useState();
  const [showTrackerDisplay, setShowTrackerDisplay] = useState(false);

  useEffect(() => {
    if (!compDataLoaded && competition) {
      dispatch(loadCompetitionData(competition));
    }
  });

  useEffect(() => {
    const alertUser = (e) => {
      if (selectedAthlete && !isFinished) {
        e.preventDefault();
        return (e.returnValue =
          'The current athlete is not finished. Are you sure you want to leave?');
      }
    };

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser, { capture: true });
    };
  }, [isFinished, selectedAthlete]);

  const resetAthleteSelector = () => {
    setShowTrackerDisplay(false);
    setSelectedAthlete(null);
  };

  const handleAthleteConfirm = async () => {
    setShowTrackerDisplay(true);
    dispatch(initializeTracker(selectedAthlete));
  };

  const handleAthleteReset = () => {
    dispatch(resetAthlete());
    dispatch(resetStopwatch());
    dispatch(resetRestCountdown());
  };

  const handleEndCompetition = () => {
    dispatch(finalizeCompetition()).then((result) => {
      if (result.actionSuccess) {
        notifySuccess('Competition has been finalized');
        navigate(`/competitions/${result.competitionId}/scores`);
      }
    });
  };

  return (
    <main className='flex flex-col max-w-4xl mx-auto px-2 sm:px-24 xl:px-2 sm:py-4 md:py-6'>
      {!competition && <Four04 />}
      {competition && (
        <div className='relative'>
          <div className='text-center mt-2 mb-3'>
            <h1 className='text-2xl md:text-3xl text-red font-medium'>
              {competition.name}
            </h1>
            <div
              className={`${
                competition.status === 'in progress'
                  ? 'text-green-600'
                  : 'text-gray-500'
              } text-center text-sm font-bold uppercase -space-x-1`}
            >
              {competition.status}
            </div>
          </div>
          <div className='w-full lg:max-w-3xl lg:mx-auto px-2 sm:px-0'>
            <div className='flex justify-between items-center gap-x-3'>
              {showTrackerDisplay ? (
                <>
                  <div className='w-full rounded-md text-white bg-blue-900/20 border border-indigo-500 py-1 px-3 text-center text-lg'>
                    {`${selectedAthlete.firstName} ${selectedAthlete.lastName}`}
                  </div>

                  <ConfirmAction
                    actionText='Reset Athlete'
                    btnLabel='Reset'
                    btnClasses='h-9 px-4 py-1 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md'
                    onConfirm={handleAthleteReset}
                  />
                </>
              ) : (
                <AthleteSelect
                  athletes={competition.athletes}
                  selectedAthlete={selectedAthlete}
                  setSelectedAthlete={setSelectedAthlete}
                />
              )}
            </div>

            {showTrackerDisplay ? (
              <div className='mt-2'>
                <StatusArea resetAthleteSelector={resetAthleteSelector} />

                <Scoreboard />

                <NextObstacle />

                <ObstacleControls />

                <TimeDisplay />

                <Obstacles />
              </div>
            ) : selectedAthlete ? (
              <button
                type='button'
                title='Confirm Athlete'
                className='mt-2 flex items-center justify-center w-full px-4 py-2 border border-transparent shadow-sm rounded-md font-medium text-sm text-white uppercase bg-blue-700 hover:bg-blue-800'
                onClick={() => handleAthleteConfirm()}
                disabled={!selectedAthlete}
              >
                <CheckIcon className='w-6 h-6' />
                <span className='ml-1'>Confirm Athlete</span>
              </button>
            ) : (
              <>
                <div className='uppercase text-white text-lg text-center'>
                  Select Athlete
                </div>

                {finishedAthletes.length > 0 && (
                  <div className='mt-4 flex justify-center'>
                    <ConfirmAction
                      actionText='End Competition'
                      btnIcon={<CheckIcon className='w-6 h-6 mr-1' />}
                      btnLabel='End Competition'
                      btnClasses='px-4 py-2 text-white bg-blue-700 hover:bg-blue-800 rounded-md'
                      onConfirm={handleEndCompetition}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </main>
  );
};

export default CompetitionTracker;
