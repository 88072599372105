import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import { classNames } from 'utils/classNames';

const HeaderMenu = () => {
  const { loggedIn, user } = useSelector((state) => state.user);
  const authLinkUrl = loggedIn ? '/signout' : '/signin';
  const authLinkText = loggedIn ? 'Sign out' : 'Sign in';

  return (
    <Menu as='div' className='relative inline-block my-auto text-left'>
      <div>
        <Menu.Button className='bg-gray-800 rounded-full flex items-center text-gray-400 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
          <span className='sr-only'>Open options</span>
          <DotsVerticalIcon className='h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute z-50 right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-600 text-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            {loggedIn && (
              <Menu.Item>
                <div
                  className={'font-medium text-white block px-4 py-2 text-sm'}
                >
                  {user?.email}
                </div>
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/password-update'
                  className={classNames(
                    active && 'bg-gray-800 font-medium',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Password Update
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/support'
                  className={classNames(
                    active && 'bg-gray-800 font-medium',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Support
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={authLinkUrl}
                  className={classNames(
                    active && 'bg-gray-800 font-medium',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {authLinkText}
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default HeaderMenu;
