import {
  INITIALIZE_TRACKER,
  RESET_ATHLETE,
  RESET_TRACKER,
  RETRY_OBSTACLE,
  SET_ON_OBSTACLE,
  UPDATE_OBSTACLES
} from './types';

import {
  incrementFailCount,
  setFailCount,
  setFinished,
  updateTotalPoints,
  updateTotalTime
} from './actions';

import {
  cloneObjArray,
  findIndexByObstacleNumber,
  getBonusPointsToAward
} from './utils';
import { getFailDeductionForUnattemptedObs } from 'utils/athletes';

// set up tracker with athlete info and competition obstacles
// @param athlete: {id, name}
export const initializeTracker = (athlete) => async (dispatch, getState) => {
  try {
    const { trackerObstacles } = await getState().competition;
    await dispatch({
      type: INITIALIZE_TRACKER,
      payload: {
        currentAthlete: athlete,
        obstacles: trackerObstacles
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// reset tracker to initial state for current athlete
export const resetAthlete = () => async (dispatch, getState) => {
  try {
    const { trackerObstacles } = await getState().competition;

    await dispatch({
      type: RESET_ATHLETE,
      payload: {
        obstacles: trackerObstacles
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// reset tracker to initial state
export const resetTracker = () => async (dispatch, getState) => {
  try {
    const { trackerObstacles } = await getState().competition;

    await dispatch({
      type: RESET_TRACKER,
      payload: {
        obstacles: trackerObstacles
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// Add bonus points to point total
export const awardBonusPoints = (obstacles) => async (dispatch, getState) => {
  try {
    const { failCount } = getState().tracker;

    if (failCount > 0) return;

    const bonusPoints = getBonusPointsToAward(obstacles);

    if (bonusPoints > 0) {
      await dispatch(updateTotalPoints(bonusPoints));
    }
    return {
      success: true
    };
  } catch (error) {
    console.log(error);
    return {
      success: false
    };
  }
};

// Update total time for current athlete
export const updateTrackerTotalTime = () => (dispatch, getState) => {
  const { stopwatch } = getState();
  const { totalTime } = stopwatch;
  dispatch(updateTotalTime(totalTime));
};

// Update obstacle data in tracker.obstacles
// @param obstacleNum: obstacle number of obstacle to update
export const updateObstacle =
  (obstacleNum, update) => async (dispatch, getState) => {
    try {
      const { obstacles, lastObstacleNum } = await getState().tracker;
      const updatedObstacles = cloneObjArray(obstacles);

      const finished = lastObstacleNum === obstacleNum;

      const targetObstacleIdx = findIndexByObstacleNumber(
        updatedObstacles,
        obstacleNum
      );

      if (targetObstacleIdx === -1) {
        return {
          error: `Obstacle ${obstacleNum} not found`
        };
      }

      const updatedObstacle = {
        ...updatedObstacles[targetObstacleIdx],
        ...update
      };

      updatedObstacles[targetObstacleIdx] = updatedObstacle;

      await dispatch({
        type: UPDATE_OBSTACLES,
        payload: updatedObstacles
      });

      await dispatch({
        type: SET_ON_OBSTACLE,
        payload: false
      });

      if (finished) {
        // award bonus points
        await dispatch(awardBonusPoints(updatedObstacles));

        await dispatch(setFinished(true));
      }

      return {
        updatedObstacle
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  };

// Mark obstacle clear and update obstacle data
// @param obstacleNum: obstacle to update
export const markObstacleClear =
  (obstacleNum) => async (dispatch, getState) => {
    const { totalTime } = getState().stopwatch;

    const update = {
      result: 'clear',
      timestamp: totalTime
    };

    await dispatch(updateObstacle(obstacleNum, update));

    return {
      success: true
    };
  };

// Mark obstacle failed and update obstacle data
// @param obstacleNum: obstacle to update
export const markObstacleFail = (obstacleNum) => async (dispatch, getState) => {
  try {
    const { totalTime } = getState().stopwatch;
    const { failCount } = getState().tracker;

    await dispatch(incrementFailCount());

    const update = {
      result: 'fail',
      timestamp: totalTime
    };

    await dispatch(updateObstacle(obstacleNum, update));

    const pointDeduction = () => {
      switch (failCount) {
        case 0:
          return -2;
        case 1:
          return -1;
        case 2:
          return -2;
        default:
          return 0;
      }
    };

    await dispatch(updateTotalPoints(pointDeduction()));

    return {
      success: true
    };
  } catch (error) {
    console.log(error);
    return {
      success: false
    };
  }
};

// Increment retry count and update obstacle data
// @param obstacleNum: obstacle to update
export const retryObstacle = (obstacleNum) => async (dispatch, getState) => {
  try {
    const { obstacles } = await getState().tracker;
    const updatedObstacles = cloneObjArray(obstacles);

    const targetObstacleIdx = findIndexByObstacleNumber(
      updatedObstacles,
      obstacleNum
    );

    const currentValue = updatedObstacles[targetObstacleIdx].retries;

    const updatedObstacle = {
      ...updatedObstacles[targetObstacleIdx],
      retries: currentValue + 1
    };

    updatedObstacles[targetObstacleIdx] = updatedObstacle;

    await dispatch({
      type: RETRY_OBSTACLE,
      payload: updatedObstacles
    });

    return {
      success: true
    };
  } catch (error) {
    console.log(error);
    return {
      success: false
    };
  }
};

// Mark obstacle bonus level completed and update obstacle data
// @param obstacleNum: obstacle to update
// @param completed: boolean indicating if bonus level was completed
export const updateBonusCompleted =
  (obstacleNum, completed) => async (dispatch, getState) => {
    try {
      const { obstacles } = await getState().tracker;
      const updatedObstacles = cloneObjArray(obstacles);

      const targetObstacleIdx = findIndexByObstacleNumber(
        updatedObstacles,
        obstacleNum
      );

      const updatedObstacle = {
        ...updatedObstacles[targetObstacleIdx],
        bonusCompleted: completed
      };

      updatedObstacles[targetObstacleIdx] = updatedObstacle;

      await dispatch({
        type: UPDATE_OBSTACLES,
        payload: updatedObstacles
      });

      return {
        success: true
      };
    } catch (error) {
      console.log(error);
      return {
        success: false
      };
    }
  };

// Handle athlete done w/o finishing
export const markAthleteDone = () => async (dispatch, getState) => {
  try {
    const { failCount, obstacles } = await getState().tracker;

    // get remaining obstacle count
    const remainingCount = obstacles.filter((ob) => ob.result === 'n/a').length;

    // update total points by deducting fail points
    const pointChange = getFailDeductionForUnattemptedObs(
      failCount,
      remainingCount
    );
    await dispatch(updateTotalPoints(pointChange));

    // update obstacleResults: mark remaining obstacles as failed
    const updatedObstacles = obstacles.map((ob) => {
      if (ob.result === 'n/a') {
        ob.result = 'fail';
      }
      return ob;
    });
    await dispatch({
      type: UPDATE_OBSTACLES,
      payload: updatedObstacles
    });

    // update fail count by # of remaining obstacles
    const updatedFailCount = failCount + remainingCount;
    dispatch(setFailCount(updatedFailCount));

    // set finished to true
    await dispatch(setFinished(true));

    return {
      success: true
    };
  } catch (error) {
    console.log(error);
    return {
      success: false
    };
  }
};

// Only called after athlete is finished
// Update bonusCompleted obstacle data & total points
// @param obstacleNum: obstacle to update
// @param bonusCompleted: boolean if bonus level was completed
// @param bonusPoints: points added or deducted for bonus level
export const updateBonusCompletedAndTotalPoints =
  (obstacleNum, completed, bonusPoints) => async (dispatch) => {
    try {
      // update bonusCompleted on obstacle
      const updateResult = await dispatch(
        updateBonusCompleted(obstacleNum, completed)
      );
      console.log('updateResult', updateResult);

      // update total points
      // - determine if added or deducted
      const pointChange = completed ? bonusPoints : bonusPoints * -1;

      // - update total points by pointChange
      await dispatch(updateTotalPoints(pointChange));

      return {
        success: true
      };
    } catch (error) {
      console.log(error);
      return {
        success: false
      };
    }
  };
