import { useSelector } from 'react-redux';

import Obstacle from './Obstacle';

const Obstacles = () => {
  const trackerObs = useSelector((state) => state.tracker.obstacles);

  return (
    <div className='mt-2 overflow-y-scroll overscroll-contain max-h-96'>
      {trackerObs &&
        trackerObs.map((ob) => (
          <Obstacle key={ob.obstacleNumber} obstacle={ob} />
        ))}
    </div>
  );
};

export default Obstacles;
