import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import AdminsOnly from 'components/AdminsOnly';
import CompetitionHeader from 'components/CompetitionHeader';
import ResultSelect from './ResultSelect';
import ResultForm from './ResultForm';
import Footer from 'components/Footer';

import { orderCompAthletesByScore } from 'utils/athletes';

const CompScoresEdit = () => {
  const { competition, competitionId } = useOutletContext();
  const { results, status } = competition;

  const [selectedResult, setSelectedResult] = useState();

  const orderedResults =
    status === 'completed' ? results : orderCompAthletesByScore(results);

  return (
    <AdminsOnly>
      <div className='relative h-full flex flex-col items-center md:px-20'>
        <CompetitionHeader
          competition={competition}
          competitionId={competitionId}
        />

        <div className='py-1 flex align-baseline justify-center min-w-full sm:max-w-2xl lg:max-w-3xl sm:px-4'>
          <h2 className='text-center text-lg text-red uppercase'>
            Edit Competition Results
          </h2>
        </div>
        <div className='grow w-full px-4 sm:px-6 lg:px-8'>
          <ResultSelect
            results={orderedResults}
            selectedResult={selectedResult}
            setSelectedResult={setSelectedResult}
          />
          {selectedResult && (
            <ResultForm result={selectedResult} competitionId={competitionId} />
          )}
        </div>
        <div className='w-full -mx-4'>
          <Footer />
        </div>
      </div>
    </AdminsOnly>
  );
};

export default CompScoresEdit;
