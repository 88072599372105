import {
  INCREMENT_FAIL_COUNT,
  INITIALIZE_TRACKER,
  RESET_ATHLETE,
  RESET_TRACKER,
  RETRY_OBSTACLE,
  SET_FAIL_COUNT,
  SET_FINISHED,
  SET_ON_OBSTACLE,
  SET_TOTAL_TIME,
  START_NEXT_OBSTACLE,
  UPDATE_OBSTACLES,
  UPDATE_TOTAL_POINTS
} from './types';

import { STARTING_POINTS } from 'store/constants';

export const trackerInitialState = {
  currentAthlete: null, // { id, name }
  totalTime: 0, // in ms
  totalPoints: STARTING_POINTS,
  failCount: 0,
  retryCount: 0,
  obstacles: [], // [{obj}] see below
  currentObstacleNum: 0,
  lastObstacleNum: 0,
  onObstacle: false,
  isFinished: false
};

/** obstacle data object
 {
   obstacleNumber: 1, 
   name: 'obstacle name',
   result: enum['n/a', 'clear', 'fail'],
   retries: enum[0, 1, 2, 3],
   bonusPoints: 0, 
   bonusCompleted: false,
   timestamp: 0
 }
 */

const reducer = (state = trackerInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INITIALIZE_TRACKER:
      const { currentAthlete, obstacles } = payload;
      return {
        currentAthlete,
        totalTime: 0,
        totalPoints: STARTING_POINTS,
        failCount: 0,
        retryCount: 0,
        obstacles,
        currentObstacleNum: 0,
        lastObstacleNum: obstacles.length,
        onObstacle: false,
        isFinished: false
      };

    case RESET_ATHLETE:
      return {
        ...trackerInitialState,
        currentAthlete: state.currentAthlete,
        obstacles: payload.obstacles,
        lastObstacleNum: payload.obstacles.length
      };

    case RESET_TRACKER:
      return {
        ...trackerInitialState,
        obstacles: payload.obstacles
      };

    case SET_TOTAL_TIME:
      return {
        ...state,
        totalTime: payload
      };

    case UPDATE_TOTAL_POINTS:
      return {
        ...state,
        totalPoints: state.totalPoints + payload
      };

    case INCREMENT_FAIL_COUNT:
      return {
        ...state,
        failCount: state.failCount + 1
      };

    case SET_FAIL_COUNT:
      return {
        ...state,
        failCount: payload
      };

    case RETRY_OBSTACLE:
      return {
        ...state,
        obstacles: payload,
        retryCount: state.retryCount + 1
      };

    case SET_ON_OBSTACLE:
      return {
        ...state,
        onObstacle: payload
      };

    case UPDATE_OBSTACLES:
      return {
        ...state,
        obstacles: payload
      };

    case START_NEXT_OBSTACLE:
      return {
        ...state,
        currentObstacleNum: payload,
        onObstacle: true
      };

    case SET_FINISHED:
      return {
        ...state,
        isFinished: payload
      };

    default:
      return state;
  }
};

export default reducer;
