import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useQuery } from 'react-query';

import Footer from 'components/Footer';
import Loader from 'components/Loader';
import CompetitionItems from './CompetitionItems';

import { PlusIcon } from '@heroicons/react/solid';

const CompetitionsList = () => {
  const { loggedIn, user } = useSelector((state) => state.user);

  const isAdminOrOrganizer =
    loggedIn && (user?.role === 'admin' || user?.role === 'organizer');

  const getCompetitions = async () => {
    const { data } = await axios.get('/api/competitions');

    return data;
  };
  const useCompetitions = () => useQuery('competitions', getCompetitions);

  const { data, error, isFetching } = useCompetitions();

  return (
    <div className='h-full overflow-y-auto flex flex-col justify-between px-2 sm:px-24 xl:px-2 pt-4 md:pt-8'>
      <div className='grow'>
        <div className='flex align-middle justify-between mb-4'>
          <h1 className='ml-8 text-center text-3xl md:text-4xl font-bold text-red'>
            Competitions
          </h1>

          {isAdminOrOrganizer && (
            <button
              type='button'
              title='Add Competition'
              className='inline-flex items-center mr-2 p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <Link
                to={`/competitions/new`}
                className='inline-flex items-center'
              >
                <PlusIcon className='h-7 w-7' aria-hidden='true' />
              </Link>
            </button>
          )}
        </div>

        {isFetching && !data ? (
          <Loader />
        ) : data && data.competitions.length > 0 ? (
          <CompetitionItems
            competitions={data.competitions}
            loggedIn={loggedIn}
            user={user}
          />
        ) : (
          <div className='py-4 text-center text-gray-500'>None found</div>
        )}
        {error && <div>Error: {error.message}</div>}
      </div>
      <div className='-mx-2 my-2'>
        <Footer />
      </div>
    </div>
  );
};

export default CompetitionsList;
