export const formatStopwatchTime = (time) => {
  const getCentiseconds = `0${Math.floor(time / 10) % 100}`.slice(-2);
  const getSeconds = `0${Math.floor(time / 1000) % 60}`.slice(-2);
  const getMinutes = `0${Math.floor(time / 60000) % 60}`.slice(-2);

  return `${getMinutes}:${getSeconds}.${getCentiseconds}`;
};

export const formatCountdownTime = (time) => {
  const getCentiseconds = `0${Math.floor(time / 10) % 100}`.slice(-2);
  const getSeconds = `0${Math.floor((time / 1000) % 60)}`.slice(-2);
  const getMinutes = `0${Math.floor(time / 60000) % 60}`.slice(-2);

  return `${getMinutes}:${getSeconds}.${getCentiseconds}`;
};

export const getMinutesAndSeconds = (time) => {
  console.log('time: ', time);
  const minutes = Math.floor(time / 60000) % 60;
  console.log('minutes: ', minutes);
  let seconds = parseFloat(((time / 1000) % 60).toFixed(3));
  console.log('seconds: ', seconds);

  return [minutes, seconds];
};

export const getMilliseconds = (minutes, seconds) => {
  const minuteMS = minutes * 60000;
  console.log('minuteMS: ', minuteMS);
  const secondMS = seconds * 1000;
  console.log('secondMS: ', secondMS);

  return minuteMS + secondMS;
};
