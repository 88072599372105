import { useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { notifyError, notifySuccess } from 'utils/toast';
import { getTrimmedStringData } from 'utils/input';
import { validatePassword } from 'utils/password';

import { userActions } from '../../store/user';

const Register = () => {
  const { token } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.user.loggedIn);

  const { registerUser } = userActions;

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: ''
    }
  });

  const formRef = useRef(null);

  const dispatchRegisterUser = async (data) => {
    try {
      const { actionSuccess } = await dispatch(registerUser(data));

      if (actionSuccess) {
        notifySuccess('Registration successful');
      } else {
        notifyError('Registration failed');
      }
    } catch (error) {
      notifyError(error.message);
      reset();
    }
  };

  const submitRegistration = async (formData) => {
    const { email, password, confirmPassword } = getTrimmedStringData(formData);

    const isValid = validatePassword(password, confirmPassword);

    if (!isValid) return;

    const submitData = {
      email,
      password,
      connectionToken: token
    };

    dispatchRegisterUser({ data: submitData });
  };

  return isLoggedIn || !token ? (
    <Navigate to={'/'} replace={true} />
  ) : (
    <div className='h-main flex items-start justify-center px-4 sm:px-6 lg:px-8 py-6 md:py-12'>
      <div className='max-w-md w-full space-y-6'>
        <div>
          <h2 className='mt-6 text-center text-3xl font-bold text-white'>
            Register
          </h2>
        </div>
        <form
          className='mt-8 space-y-4'
          ref={formRef}
          onSubmit={handleSubmit(submitRegistration)}
        >
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-300'
            >
              Email address:
              <span className='text-xs float-right'>
                {' '}
                Must match address that received invitation
              </span>
            </label>
            <input
              id='email'
              {...register('email')}
              name='email'
              type='email'
              autoComplete='email'
              required
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
              placeholder='Email address'
            />
          </div>
          <div>
            <label
              htmlFor='password'
              className='block text-sm font-medium text-gray-300'
            >
              Password
            </label>
            <input
              id='password'
              {...register('password')}
              name='password'
              type='password'
              autoComplete='current-password'
              required
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
              placeholder='Password'
            />
          </div>
          <div>
            <label
              htmlFor='confirmPassword'
              className='block text-sm font-medium text-gray-300'
            >
              Confirm Password
            </label>
            <input
              id='confirmPassword'
              {...register('confirmPassword')}
              name='confirmPassword'
              type='password'
              autoComplete='current-password'
              required
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm'
              placeholder='Confirm Password'
            />
          </div>

          <div className='pt-2'>
            <button
              type='submit'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              disabled={isLoggedIn}
            >
              <span className='flex items-center pl-3 uppercase'>Register</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
