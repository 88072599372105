import axios from 'axios';

import {
  AUTH_PROCESSING,
  AUTO_LOGIN_USER_FAILURE,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER_FAILURE,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SET_USER_DATA
} from './types';

import { setAuthTokenHeader } from '../../utils/auth-token';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const setAuthProcessing = (bool) => ({
  type: AUTH_PROCESSING,
  payload: bool
});

export function login(data) {
  return async (dispatch, getState) => {
    let actionSuccess;

    try {
      const {
        user: { isAuthProcessing }
      } = getState();

      if (!isAuthProcessing) {
        await dispatch(setAuthProcessing(true));
      } else {
        return;
      }

      const body = JSON.stringify(data);

      const {
        data: { token }
      } = await axios.post(`/api/auth/login`, body, config);

      const payload = {
        token
      };

      setAuthTokenHeader(token);

      await dispatch({
        type: LOGIN_USER_SUCCESS,
        payload
      });

      const result = await dispatch(getUser());

      actionSuccess = result.actionSuccess;
      return { actionSuccess };
    } catch (err) {
      console.log('login error: ', err);
      actionSuccess = false;

      await dispatch({
        type: LOGIN_USER_FAILURE,
        payload: err
      });
    } finally {
      await dispatch(setAuthProcessing(false));

      return { actionSuccess };
    }
  };
}

export function logout() {
  return async (dispatch) => {
    let actionSuccess;

    try {
      await dispatch({
        type: LOGOUT_USER
      });

      actionSuccess = true;
    } catch (err) {
      console.error(err);
      actionSuccess = false;
    } finally {
      setAuthTokenHeader(null);

      return { actionSuccess };
    }
  };
}

export function getUser() {
  return async (dispatch) => {
    let actionSuccess;
    try {
      await dispatch(setAuthProcessing(true));

      const {
        data: { user }
      } = await axios.get(`/api/users/token`, config);

      actionSuccess = true;

      await dispatch({
        type: SET_USER_DATA,
        payload: user
      });
    } catch (err) {
      console.error(err);
      actionSuccess = false;

      await dispatch({
        type: AUTO_LOGIN_USER_FAILURE
      });
    } finally {
      await dispatch(setAuthProcessing(false));

      return { actionSuccess };
    }
  };
}

export function authTokenLogin(token) {
  return async (dispatch) => {
    try {
      setAuthTokenHeader(token);

      await dispatch(getUser());
    } catch (err) {
      console.error(err);
    }
  };
}

export function registerUser(data) {
  return async (dispatch) => {
    let actionSuccess;

    try {
      await dispatch(setAuthProcessing(true));

      const body = JSON.stringify(data);

      const {
        data: { token }
      } = await axios.post(`/api/auth/register`, body, config);

      const payload = {
        token
      };

      setAuthTokenHeader(token);

      await dispatch({
        type: REGISTER_USER_SUCCESS,
        payload
      });

      const result = await dispatch(getUser());

      actionSuccess = result.actionSuccess;
      return { actionSuccess };
    } catch (err) {
      console.log('register error: ', err);
      actionSuccess = false;

      await dispatch({
        type: REGISTER_USER_FAILURE,
        payload: err
      });
    } finally {
      await dispatch(setAuthProcessing(false));

      return { actionSuccess };
    }
  };
}

export function resetPassword(resetData) {
  return async (dispatch) => {
    let actionSuccess;

    try {
      await dispatch(setAuthProcessing(true));

      const body = JSON.stringify({ data: resetData });

      const {
        data: { token }
      } = await axios.post(`/api/auth/password-reset`, body, config);

      const payload = {
        token
      };

      setAuthTokenHeader(token);

      await dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload
      });

      const result = await dispatch(getUser());

      actionSuccess = result.actionSuccess;
    } catch (err) {
      console.log('pwd reset error: ', err);
      actionSuccess = false;

      await dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: err
      });
    } finally {
      await dispatch(setAuthProcessing(false));

      return { actionSuccess };
    }
  };
}

export function updatePassword(updateData) {
  return async (dispatch) => {
    let actionSuccess;

    try {
      await dispatch(setAuthProcessing(true));

      const body = JSON.stringify({ data: updateData });

      const { data } = await axios.put(
        `/api/users/password-update`,
        body,
        config
      );

      actionSuccess = data.success;
    } catch (err) {
      console.log('pwd reset error: ', err);
      actionSuccess = false;
    } finally {
      await dispatch(setAuthProcessing(false));

      return { actionSuccess };
    }
  };
}
