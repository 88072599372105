import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userActions } from '../../store/user';

import { notifyError, notifySuccess } from 'utils/toast';

const SignOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logout } = userActions;

  const handleClick = () => {
    try {
      dispatch(logout());

      notifySuccess('You are signed out');

      navigate('/signin');
    } catch (error) {
      notifyError('Oops, please try again or contact support');
    }
  };

  return (
    <div className='h-main flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='mt-6 text-center text-3xl font-bold text-white'>
            Sign Out
          </h2>
        </div>

        <div>
          <button
            type='button'
            onClick={handleClick}
            className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <span className='absolute left-0 inset-y-0 flex items-center pl-3'></span>
            Sign Out
          </button>
        </div>

        <div className='flex items-center justify-center'>
          <div className='text-sm'>
            <Link
              to='/'
              className='font-medium text-white uppercase hover:text-indigo-500'
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignOut;
