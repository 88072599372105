import { useSelector } from 'react-redux';

import StopwatchBtn from './StopwatchBtn';

import { classNames } from 'utils/classNames';

import { formatCountdownTime, formatStopwatchTime } from 'utils/time';

const TimeDisplay = () => {
  const stopwatchTotal = useSelector((state) => state.stopwatch.totalTime);
  const countdown = useSelector((state) => state.countdown.timeRemaining);
  const isCountdownRunning = useSelector(
    (state) => state.countdown.isCountdownRunning
  );
  const isCountdownExpired = useSelector((state) => state.countdown.isExpired);

  const { isFinished } = useSelector((state) => state.tracker);

  return (
    <div className='relative flex flex-col items-center px-2 py-1 text-white bg-blue-900/20 border border-indigo-500 rounded-xl'>
      {!isCountdownRunning && stopwatchTotal > 0 && !isFinished && (
        <div className='absolute left-4 sm:left-16 top-2/4 -translate-y-2/4 z-50'>
          <StopwatchBtn />
        </div>
      )}
      <div
        className={classNames(
          'w-full relative text-center transition-all',
          !isCountdownRunning ? 'text-3xl' : 'text-xl'
        )}
      >
        <span className='uppercase mr-2'>Time: </span>
        <span className='tabular-nums'>
          {formatStopwatchTime(stopwatchTotal)}
        </span>
      </div>
      {!isFinished && (
        <div
          className={classNames(
            'w-full relative text-center transition-all',
            isCountdownRunning ? 'text-3xl' : 'text-xl'
          )}
        >
          <span className='uppercase mr-2'>Rest Time:</span>
          <span
            className={classNames(
              'tabular-nums',
              isCountdownExpired && 'text-red'
            )}
          >
            {formatCountdownTime(countdown)}
          </span>
        </div>
      )}
    </div>
  );
};

export default TimeDisplay;
