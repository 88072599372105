import { format, parseISO } from 'date-fns';

export const formatDateMDYT = (date) =>
  format(parseISO(date), 'MMMM dd, yyyy p');

export const formatDateMDY = (date) => format(parseISO(date), 'MMMM dd, yyyy');

export const formatDateYDM = (date) => format(parseISO(date), 'yyyy-MM-dd');

export const formatUtcDateTime = (date) =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");

export const formatUtcDate = (date) => format(new Date(date), 'yyyy-MM-dd');

export const formatToUtc = (date) =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
